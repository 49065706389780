angular.module('headart.magazine.filters', [])
.filter('toCurrencySymbol', ["$rootScope", function($rootScope) {
    return function(input) {
        switch(input){
            case 'GBP':
                return "£";
            case 'EUR':
                return "€";
            case 'USD':
                return "$";
            case 'AUD':
                return "$";
            case 'BTC':
                return "฿";
            default:
                return input;
        }
    };
}])
.filter('languageName', ["$filter", function($filter) {
    return function(input) {
        var languages = [
            {code: 'nl-NL', name: 'DUTCH'},
            {code: 'en-GB', name: 'ENGLISH'},
            {code: 'en-US', name: 'ENGLISH_US'},
            {code: 'fr-FR', name: 'FRENCH'},
            {code: 'de-DE', name: 'GERMAN'}
        ];
        var language = ($filter('filter')(languages, function(value, key) {
            return (value.code.toLowerCase() == input.toLowerCase()) || (value.code.split('-')[0] == input.toLowerCase());
        }));
        return language.length && language[0].name || input;
    };
}]);