angular.module('headart.dev', [])
.controller('Dev2Ctrl', ["$scope", "$stateParams", "$timeout", "HAImageService", "$q", "$http", "$window", function($scope, $stateParams, $timeout, HAImageService, $q, $http, $window) {

    var elm = angular.element(document.querySelector('.test-mag'));
    elm.html("<iframe src=\"https://player.vimeo.com/video/130624271?loop=1&player_id=player_7&api=1\" id=\"player_7\" width=\"1050\" height=\"578\" frameborder=\"0\" title=\"Jeanne d&#039;Arc Redux 3min H264trentem&oslash;ller\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>");
    console.log(elm);


    // $scope.createFlickityMagazine = function(elmSelector, startPage) {
    //     var self = this;

    //     var flkty = new Flickity(elmSelector, {
    //         cellAlign: 'left',
    //         cellSelector: '.test-page',
    //         friction: 0.3,
    //         percentPosition: true,
    //         prevNextButtons: true,
    //         pageDots: true,
    //         accessibility: false
    //     });

    //     return flkty;
    // };

    // $scope.testpages = [
    //     {img: "http://headart.dev/api/v1/magazine/1/image/6", text: "Hi there 1"},
    //     {img: "http://headart.dev/api/v1/magazine/1/image/7", text: "Hi there 2"},
    //     {img: "http://headart.dev/api/v1/magazine/1/image/8", text: "Lorem ipsum dolor sit amet, offendit legendos ut vix, vim no tale cetero. Mazim evertitur mea ei, per te sumo platonem. At invenire repudiandae ullamcorper cum. "
    //         + "<br> No has vivendo torquatos, has lorem appareat ea, vel animal adolescens an. Regione reformidans eu pri, ad sit commodo equidem luptatum. Sea ea essent doctus iriure, eius ornatus fuisset ei sea. Sed cu pericula constituto disputationi."
    //         + "<br> Ne novum nusquam sea, nam diam etiam utinam in. Alia dolorem fabellas ea eos, nec mediocrem quaerendum adversarium id. Pro an novum invenire deterruisset. Nullam disputando adversarium at has. No case euismod debitis mel, meis expetendis mei ei."
    //         + "<br> No has vivendo torquatos, has lorem appareat ea, vel animal adolescens an. Regione reformidans eu pri, ad sit commodo equidem luptatum. Sea ea essent doctus iriure, eius ornatus fuisset ei sea. Sed cu pericula constituto disputationi."
    //         + "<br> Ne novum nusquam sea, nam diam etiam utinam in. Alia dolorem fabellas ea eos, nec mediocrem quaerendum adversarium id. Pro an novum invenire deterruisset. Nullam disputando adversarium at has. No case euismod debitis mel, meis expetendis mei ei."
    //         + "<br> No has vivendo torquatos, has lorem appareat ea, vel animal adolescens an. Regione reformidans eu pri, ad sit commodo equidem luptatum. Sea ea essent doctus iriure, eius ornatus fuisset ei sea. Sed cu pericula constituto disputationi."
    //         + "<br> Ne novum nusquam sea, nam diam etiam utinam in. Alia dolorem fabellas ea eos, nec mediocrem quaerendum adversarium id. Pro an novum invenire deterruisset. Nullam disputando adversarium at has. No case euismod debitis mel, meis expetendis mei ei."
    //         + "<br> No has vivendo torquatos, has lorem appareat ea, vel animal adolescens an. Regione reformidans eu pri, ad sit commodo equidem luptatum. Sea ea essent doctus iriure, eius ornatus fuisset ei sea. Sed cu pericula constituto disputationi."
    //         + "<br> Ne novum nusquam sea, nam diam etiam utinam in. Alia dolorem fabellas ea eos, nec mediocrem quaerendum adversarium id. Pro an novum invenire deterruisset. Nullam disputando adversarium at has. No case euismod debitis mel, meis expetendis mei ei."
    //     },
    //     {img: "http://headart.dev/api/v1/magazine/1/image/9", text: "Hi there 3"},
    // ];

    // $timeout(function() {
    //     //$scope.flkty = $scope.createFlickityMagazine('.test-mag');
    // }, 200);
    
}])
.controller('Prototype1Ctrl', ["$scope", "$stateParams", "$timeout", "HAImageService", "$q", "$http", function($scope, $stateParams, $timeout, HAImageService, $q, $http) {
    /*
    $scope.magazine = new Flickity('.magazine', {
        cellAlign: 'left',
        cellSelector: '.page',
        friction: 0.3,
        percentPosition: true,
        prevNextButtons: false,
        pageDots: false
    });

    $scope.showData = false;
    $scope.toolbar = {
        show: false,
        hover: false,
        timeout: null
    };
    $scope.currentPage = 0;
    $scope.totalPages = 0;
    $scope.totalWidth = 0;
    $scope.autoplay = false;
    $scope.music = true;
    $scope.pageSwitcher = false;
    $scope.settings = false;
    $scope.fullscreen = false;
    $scope.shareSheet = false;
    $scope.isMobile = false;  


    // Set focus on magazine
    document.querySelector('.magazine').focus();
    */



    /**
     *
     * Testing image service - loading image and cancelling
     * 
     */
    /*

    var url = "http://imgsrc.hubblesite.org/hu/db/images/hs-2015-02-a-hires_jpg.jpg"
    //var url = "magazines/demo-mag-16-10/7T2x8OSc.jpg"
    var options = {
        useHttp: false      //we can either load image via normal method (fastest)
        //useHttp: true     //or we can preload via http request (allows for guaranteed cancelling, but more resource intensive)
    };
    var imageService = new HAImageService(options);

    //load our image after a 3s delay
    var imagePromise = imageService.loadImage(url, 1000);
    
    //test 1 - cancel image before timeout ends
    //imageService.cancel(imagePromise);

    //test 2 - cancel image after image starts downloading
    $timeout(function() {
        imageService.cancel(imagePromise);
        imageService.cancel(imagePromise);
        imageService.cancel(imagePromise);
    }, 3000);
   imagePromise.then(function(data) {
        console.log('image loaded');
    }, function(err) {
        console.log('image not loaded', err)
    });

    return;

    //*/





   /**
    * Testing Inage Service - various drawing tests
    *
    * 
    */
    var myImage = new HAImageService();
    var imgSrc = ["http://headart.dev/api/v1/magazine/1/image/6", "http://headart.dev/api/v1/magazine/1/image/7"];
    var options = {
        canvasW: window.innerWidth,
        canvasH: window.innerHeight,
        background: 'black',

        imgScale: 'cover',    //image sizing options: 'cover', 'contain', {float} scale
        imgCenterX: true,       //center image
        imgCenterY: true,
        imgPositionX: null,     //x position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerX)
        imgPositionY: null,     //y position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerY)
        sampleSizeX: 20,        //width of area to sample from given point
        sampleSizeY: 20,        //height of area to sample from given point
        enableDebug: true,      //show debugging square over point
    };
    var canvas = angular.element(myImage.canvas).css('position', 'absolute');
    document.body.appendChild(canvas[0]);

    //init the HAImageService to draw image and set canvas size
    myImage.getSampleAverage(imgSrc, 0, 0, options);

    /*
     * 1. test drawing of left and right spread, cover mode with x and y centering
     * 
     */
    
    // //init the HAImageService to draw image and set canvas size
    // myImage.getSampleAverage(imgSrc, 0, 0, options);
    // //update canvas on mouse move
    // canvas.on('mousemove', function(event) {
    //     //console.log(event.x, event.y);
    //     var pointX = event.x;
    //     var pointY = event.y;
    //     var options = {
    //         canvasW: window.innerWidth,
    //         canvasH: window.innerHeight,
    //     }
    //     myImage.getSampleAverage(imgSrc, pointX, pointY, options).then(function(result) {
    //         console.log("x="+pointX + " y="+pointY, result.color, result.brightness, result.debug);
    //     });
    // });


    /*
     * 2. test drawing of left spread, cover mode with x position 100%
     * 
     */
    // canvas.on('mousemove', function(event) {
    //     //console.log(event.x, event.y);
    //     var pointX = event.x;
    //     var pointY = event.y;
    //     var options = {
    //         imgScale: "cover",
    //         canvasW: window.innerWidth,
    //         canvasH: window.innerHeight,

    //         //imgCenterX: true,       //center image
    //         //imgCenterY: true,
    //         imgPositionX: "0%",     //x position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerX)
    //         imgPositionY: null,     //y position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerY)
    //     }
    //     myImage.getSampleAverage(imgSrc[0], pointX, pointY, options).then(function(result) {
    //         console.log("x="+pointX + " y="+pointY, result.color, result.brightness, result.debug);
    //     });
    // });



    /*
     * 3. test drawing gaps on the canvas, with new alternative imgSrc syntax
     * 
     */
    
    //init the HAImageService to draw image and set canvas size
    imgSrc = [{url: null, width: null, height: null, bgColor: 'black'}, {url: "magazines/demo-mag-16-10/jNgP8thI.jpg"}];
    //imgSrc = [{url: null, width: null, height: null, bgColor: 'black'}, {url: null, width: null, height: null, bgColor: 'white'}];
    myImage.getSampleAverage(imgSrc, 0, 0, options);
    //update canvas on mouse move
    canvas.on('mousemove', function(event) {
        //console.log(event.x, event.y);
        var pointX = event.x;
        var pointY = event.y;
        var options = {
            imgScale: "cover",
            canvasW: window.innerWidth,
            canvasH: window.innerHeight,
        }
        myImage.getSampleAverage(imgSrc, pointX, pointY, options).then(function(result) {
            console.log("x="+pointX + " y="+pointY, result.color, result.brightness, result.debug);
        });
    });
}]);
