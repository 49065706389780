/* Services */
angular.module('headart.image.services', [])
.service('HAImageService', ["$rootScope", "$log", "$q", "$timeout", "$http", function($rootScope, $log, $q, $timeout, $http){
    var HAImageService = function(options){
        this.canvas = document.createElement("canvas");
        this.tempCanvas = document.createElement("canvas");
        this.ctx = null;            //cached canvas ctx from last draw
        this.defaults = {
            canvasW: null,
            canvasH: null,
            background: 'white',    //background color to draw on canvas
            imgScale: 'contain',    //image sizing options: 'cover', 'contain', {float} scale
            imgCenterX: true,       //center image
            imgCenterY: true,
            imgPositionX: null,     //x position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerX)
            imgPositionY: null,     //y position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerY)
            sampleSizeX: 20,        //width of area to sample from given point
            sampleSizeY: 20,        //height of area to sample from given point

            redrawImage: true,      //optimisation: set to false to reuse previously drawn image

            useHttp: false,         //use http to load images - allows for requests to be cancelled

            enableDebug: false,     //show debugging square over point
        };

        this.config = angular.extend({}, this.defaults, options);
    };

    /**
     * set the canvas element to use
     * @param {element} elm DOM canvas element
     */
    HAImageService.prototype.setCanvas = function(elm) {
        this.canvas = elm;
    };

    /**
     * clear any cached data (e.g. the canvas ctx)
     */
    HAImageService.prototype.clearCache = function() {
        this.ctx = null;
    };

    /**
     * load an image
     * @param  {str} url        image url
     * @param  {int} delay      time in ms to delay execution of image loading
     * @param  {bool} useHttp   use http to load image (overrides config setting). NB: results in different resolved data
     * @return {promise}        resolves to image obj, or img data (when using http) upon load
     */
    HAImageService.prototype.loadImage = function(url, delay, useHttp) {
        var self = this;
        var deferred = $q.defer();

        useHttp = typeof useHttp == "undefined" ? self.config.useHttp : useHttp;
        if (useHttp) {
            var httpTimeout = $q.defer();
            var opts = {
                method: 'GET',
                url: url,
                //cache: true,      //need to think about memory usage
                timeout: httpTimeout.promise
            };
            deferred.promise._httpTimeout = httpTimeout;

            $timeout(function() {
                return $http(opts).then(function(res) {
                    //deferred.resolve(img[0]);
                    deferred.resolve(res.data);
                }, function(res) {
                    if (res.status == -1) {
                        //canceled
                        deferred.reject("cancelled");
                    } else {
                        $log.error('Unable to load image (http): ' + url, res.statusText);
                        deferred.reject(res.statusText);
                    }
                });
            }, delay);

        } else {
            //load the image (NB: listener will unbind itself due to .one())
            var img = angular.element(new Image());
            var pageUnbind1 = img.bind('load', function (event) {
                deferred.resolve(img[0]);
                img.unbind();
            });
            var pageUnbind2 = img.bind('error', function(err) {
                if (img.attr('src') == '') {
                    // loading was aborted
                    deferred.reject('CANCELLED');
                } else {
                    $log.error('Unable to load image: ' + url, err);
                    deferred.reject(err);
                }
                img.unbind();
            });

            //trigger loading of image via timeout (allows for delay, and to be cancelled)
            deferred.promise._imageTimeout = $timeout(function() {
                img.attr('src', url);
            }, delay);
            deferred.promise._image = img;  //bind img to promise, to it can be cancelled
        }

        return deferred.promise;
    };

    /**
     * get embedding data for a video via oEmbed standard
     * @param  {str} url        video url
     * @param  {int} delay      time in ms to delay execution of request loading
     * @return {promise}        resolves to embed data obj
     */
    HAImageService.prototype.oembedVideo = function(url, args, delay) {
        var self = this;
        var deferred = $q.defer();
        var defaults = {
            url: url,          // (required) The Vimeo URL for a video.
            width: null,       // The exact width of the video. Defaults to original size.
            maxwidth: null,    // Same as width, but video will not exceed original size.
            height: null,      // The exact height of the video. Defaults to original size.
            maxheight: null,   // Same as height, but video will not exceed original size.
            byline: true,      // Show the byline on the video. Defaults to true.
            title: true,       // Show the title on the video. Defaults to true.
            portrait: true,    // Show the user’s portrait on the video. Defaults to true.
            color: null,       // Specify the color of the video controls.
            callback: null,    // When returning JSON, wrap in this function.
            autoplay: false,   // Automatically start playback of the video. Defaults to false.
            loop: false,       // Play the video again automatically when it reaches the end. Defaults to false.
            autopause: true,   // Pause this video automatically when another one plays. Defaults to true.
            xhtml: false,      // Make the embed code XHTML compliant. Defaults to false.
            api: true,         // Enable the JavaScript API. Defaults to false.
            player_id: null,   // A unique id for the player that will be passed back with all JavaScript API responses.
        };
        var config = angular.extend({}, defaults, args);

        var httpTimeout = $q.defer();
        var opts = {
            method: 'GET',
            url: 'https://vimeo.com/api/oembed.json',
            params: config,
            //cache: true,      //need to think about memory usage
            timeout: httpTimeout.promise
        };
        deferred.promise._httpTimeout = httpTimeout;

        $timeout(function() {
            return $http(opts).then(function(res) {
                //deferred.resolve(img[0]);
                deferred.resolve(res.data);
            }, function(res) {
                if (res.status == -1) {
                    //canceled
                    deferred.reject("cancelled");
                } else {
                    $log.error('Unable to load oEmbed video ' + url, res.statusText);
                    deferred.reject(res.statusText);
                }
            });
        }, delay);

        return deferred.promise;
    };

    /**
     * cancels loading of an image (when http used)
     * @param  {promise} promise    promise returned from loadImage()
     */
    HAImageService.prototype.cancel = function(promise) {
        if (promise._httpTimeout) {
            promise._httpTimeout.resolve('ABORT');
        } else if (promise._imageTimeout) {
            $timeout.cancel(promise._imageTimeout);
            promise._image.attr('src', ''); //not a guaranteed way to cancel, but many browsers will cancel the download
        }
    };

    /**
     * get the average colour and brightness for a sample area on the given image
     * @param  {multi} imgSrc   polymorphic: can be single or array of either image urls (string) or objects with settings as follows:
     *                                {url: 'url, width: int, height: int, bgColor: '#FFF'}      
     *                                multiple images are combined horizontally when drawn
     *                                if 'url' is blank, then a blank image of the width/height will be drawn. if width/height is blank, the canvas width/total images will be used
     * @param  {int} pointX     X coord of sample area top left point
     * @param  {int} pointY     Y coord of sample area top left point
     * @param  {obj} options    additional config options
     * @return {promise}         
     */
    HAImageService.prototype.getSampleAverage = function(imgSrc, pointX, pointY, options) {
        var self = this;
        var image = null;
        
        pointX = pointX || 0;
        pointY = pointY || 0;

        return $q.when(true)
        .then(function() {
            //if config.redrawImage == false, the new images won't be loaded and the old drawn image will be used instead
            //NB: the canvas really needs to have been drawn before calling this...be careful when working with promises/async as this optimisation may be ignored
            var config = angular.extend({}, self.defaults, options);
            if (config.redrawImage || !self.ctx) {
                //load the image(s)
                if (Array.isArray(imgSrc)) {
                    var promises = [];
                    imgSrc.forEach(function(image, index) {
                        if (typeof image == "object") {
                            if (image.url) {
                                //load the image by it's url
                                promises.push(self.loadImage(image.url, null, false));
                            } else {
                                //no image to be loaded (i.e. a gap is to be drawn on the canvas)
                                promises.push($q.resolve(image));
                            }
                        } else {
                            promises.push(self.loadImage(image, null, false));
                        }
                    });
                    return $q.all(promises);
                } else {
                    if (typeof image == "object") {
                        promises.push(self.loadImage(image, null, false));
                        if (image.url) {
                            //load the image by it's url
                                return $q.all([self.loadImage(image.url, null, false)]);
                        } else {
                            //no image to be loaded (i.e. a gap is to be drawn on the canvas)
                            return $q.all([$q.resolve(image)]);
                        }
                    } else {
                        return $q.all([self.loadImage(imgSrc, null, false)]);
                    }
                }
            } else {
                return [];
            }
        })
        .then(function(images) {
            var config = angular.extend({}, self.defaults, options);
            if (config.redrawImage || !self.ctx) {
                $log.debug("HAImageService: drawing image on canvas");

                var totalImageWidth = 0;
                var maxImageHeight = 0;
                var gaps = 0;   //number of gaps to add
                var gapWidth = 0;
                images.forEach(function(img, index) {
                    if (!img.width) {
                        //if no img width provided indicate a gap must be calculated
                        gaps++;
                    }
                    totalImageWidth += img.width;
                    maxImageHeight = Math.max(maxImageHeight, img.height);
                });

                if (gaps > 0) {
                    //calculate width for gaps
                    if (gaps == images.length) {
                        gapWidth = Math.floor(config.canvasW/gaps);
                    } else {
                        gapWidth = totalImageWidth*(gaps/(images.length-gaps));
                    }

                    //set gap width to blank images and recalculate total width & max height
                    totalImageWidth += gapWidth*gaps;
                    maxImageHeight = maxImageHeight || config.canvasH;
                    images.forEach(function(img, index) {
                        if (!img.width) {
                            img.width = gapWidth;
                        }
                    });
                }


                //set canvas sizes
                self.tempCanvas.width = totalImageWidth;
                self.tempCanvas.height = maxImageHeight;
                self.canvas.width = config.canvasW || totalImageWidth;
                self.canvas.height = config.canvasH || maxImageHeight;

                //set the canvas background colors
                var tempCtx = self.tempCanvas.getContext("2d");
                tempCtx.rect(0, 0, self.tempCanvas.width, self.tempCanvas.height);
                tempCtx.fillStyle = config.background;
                tempCtx.fill();
                self.ctx = self.canvas.getContext("2d");
                self.ctx.rect(0, 0, self.canvas.width, self.canvas.height);
                self.ctx.fillStyle = config.background;
                self.ctx.fill();

                //image scaling
                var imageScaledX, imageScaledY;
                if (!isNaN(config.imgScale)) {
                    //use numeric scaling
                    imageScaledX = totalImageWidth * config.imgScale;
                    imageScaledY = maxImageHeight * config.imgScale;
                } else {
                    //scale to cover/contain in canvas
                    var scaleWidth = config.canvasW / totalImageWidth;
                    var scaleHeight = config.canvasH / maxImageHeight;

                    var scaleCover = Math.max(scaleWidth, scaleHeight);
                    var scaleContain = Math.min(scaleWidth, scaleHeight);

                    if (config.imgScale == "cover") {
                        imageScaledX = totalImageWidth * scaleCover;
                        imageScaledY = maxImageHeight * scaleCover;
                    } else if (config.imgScale == "contain") {
                        imageScaledX = totalImageWidth * scaleContain;
                        imageScaledY = maxImageHeight * scaleContain;
                    } else {
                        //draw at native
                        imageScaledX = totalImageWidth;
                        imageScaledY = maxImageHeight;
                    }
                }
                
                //image positioning
                var imageDrawX = 0;
                var imageDrawY = 0;
                if (config.imgPositionX !== null) {
                    //pixel value
                    //imageDrawX = config.imgPositionX;

                    //percentage value, calulated as x% of image placed at x% of container
                    var canvasOffsetX = config.canvasW * (parseFloat(config.imgPositionX)/100);
                    var imgOffsetX = imageScaledX * (parseFloat(config.imgPositionX)/100);
                    imageDrawX = Math.floor(canvasOffsetX-imgOffsetX);
                } else if (config.imgCenterX) {
                    imageDrawX = Math.floor((config.canvasW/2) - (imageScaledX/2));
                }

                if (config.imgPositionY !== null) {
                    //pixel value
                    //imageDrawY = config.imgPositionY;

                    //percentage value, calulated as y% of image placed at y% of container
                    var canvasOffsetY = config.canvasH * (parseFloat(config.imgPositionY)/100);
                    var imgOffsetY = imageScaledY * (parseFloat(config.imgPositionY)/100);
                    imageDrawY = Math.floor(canvasOffsetY-imgOffsetY);
                } else if (config.imgCenterY) {
                    imageDrawY = Math.floor((config.canvasH/2) - (imageScaledY/2));
                }

                //draw all images on temp canvas first, then scale and position on final canvas
                var offsetX = 0;
                images.forEach(function(img, index) {
                    //draw images, or blank spaces if no img src
                    if (img.src) {
                        tempCtx.drawImage(img, offsetX, 0, img.width, img.height);
                    } else {
                        if (img.bgColor) {
                            tempCtx.fillStyle = img.bgColor;
                        } else {
                            tempCtx.fillStyle = config.background;
                        }
                        tempCtx.fillRect(offsetX, 0, img.width, maxImageHeight);
                    }
                    //increase the offset to get images side-by-side
                    offsetX += img.width;
                });

                //final drawing
                //var fullImage = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
                //console.log(totalImageWidth, maxImageHeight, images[0].width, images[0].height);
                self.ctx.drawImage(self.tempCanvas, 0, 0, totalImageWidth, maxImageHeight, imageDrawX, imageDrawY, imageScaledX, imageScaledY);
            }

            //get the image data for the sample area
            var imageData = self.ctx.getImageData(pointX, pointY, config.sampleSizeX, config.sampleSizeY);
            var data = imageData.data;
            var r,g,b,avg;
            var colorSum = 0;

            var sumRed=0, sumGreen=0, sumBlue=0;

            //calculate the average color and brightness
            for(var i = 0; i < data.length; i+=4) {
                r = data[i];
                g = data[i+1];
                b = data[i+2];

                avg = Math.floor((r+g+b)/3);
                colorSum += avg;

                sumRed += data[i];
                sumGreen += data[i+1];
                sumBlue += data[i+2];
            }

            var avgColor = {
                r: Math.floor(sumRed / (config.sampleSizeX*config.sampleSizeY)),
                g: Math.floor(sumGreen / (config.sampleSizeX*config.sampleSizeY)),
                b: Math.floor(sumBlue / (config.sampleSizeX*config.sampleSizeY)),
            };
            var avgBrightness = Math.floor(colorSum / (config.sampleSizeX*config.sampleSizeY));

            //debug mode: draw a rectangle with the average color, and a black/white border
            if (config.enableDebug) {
                self.ctx.fillStyle='rgb('+ avgColor.r + ',' + avgColor.g + ',' + avgColor.b + ')';
                if (avgBrightness > 128) {
                    self.ctx.strokeStyle="black";
                } else {
                    self.ctx.strokeStyle="white";
                }
                self.ctx.strokeRect(pointX, pointY, config.sampleSizeX, config.sampleSizeY);
                self.ctx.fillRect(pointX, pointY, config.sampleSizeX, config.sampleSizeY);
            }

            //return the results
            var finalResult = {
                color: avgColor,
                brightness: avgBrightness
            };
            if (config.enableDebug) {
                finalResult.debug = {
                    imageDrawX: imageDrawX,
                    imageDrawY: imageDrawY,

                    imgPositionX: config.imgPositionX,
                    canvasOffsetX: canvasOffsetX,
                    imgOffsetX: imgOffsetX,

                };
            }
            return finalResult;
        });
    };


    return HAImageService;
}]);