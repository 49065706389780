/* Services */
angular.module('headart.magazine.services', [])
.factory('MagazineAPI', ["$resource", "api_url", "AuthService", "$stateParams", function($resource, api_url, AuthService, $stateParams) {
    //if the user is logged in and "preview" is in stateParams request unpublished content from backend
    var isPreview = null;
    if (AuthService.isLoggedIn && $stateParams.preview) {
        // @TODO $stateParams are only injected once and so don't update when user navigates.
        // as a result once preview is enabled, all api calls will return drafts until user refreshes page
        // a better way to do this is rely on the controller calling the resource fn to pass the preview param
        isPreview = true;
    }
    return $resource(api_url+'magazine/:id', {id:'@id', preview: isPreview}, {
        //additional actions
        list: {
            method: 'GET',
            params: {limit:50, page:1, sort:"lname", preview: isPreview},
            isArray: true   //@TODO remove, will be using pagination in future
        },
        getBySlug: {
            method: 'GET',
            url: api_url+'magazine/:slug',
            params: {slug: '@slug', preview: isPreview}
        },
        getProtectedBySlug: {
            method: 'POST',
            url: api_url+'magazine/:slug',
            params: {slug: '@slug', preview: isPreview}
        },
        getProtected: {
            method: 'POST',
            url: api_url+'magazine/:slug',
            params: {slug: '@slug', preview: isPreview}
        },
        autocomplete:{
            method: 'GET',
            url: api_url+'autocomplete/magazine',
            params: {query:null, preview: isPreview},
            isArray: true
        }
    });
}])
.service('MagazineControlService', function() {
    var MagazineControlService = function(options) {
        this.display = {
            pageSwitcher: false,
            shareSheet: false,
            settings: false,            toolbar: false,
            pageContent: true
        };
    };

    return new MagazineControlService();
})
.factory('MagazineWorkerService', function() {

    var worker = new Worker('doWork.js');
    var defer = $q.defer();
    worker.addEventListener('message', function(e) {
      console.log('Worker said: ', e.data);
      defer.resolve(e.data);
    }, false);

    return {
        doWork : function(myData){
            defer = $q.defer();
            worker.postMessage(myData); // Send data to our worker.
            return defer.promise;
        }
    };
})
.service('Magazine', ["$rootScope", "$log", "MagazineAPI", "api_url", "public_url", "$q", "$timeout", "$window", "HAImageService", "$state", "$interval", "debounce", "MagazineControlService", "PhantomJs", "MetaData", "$location", "$filter", function($rootScope, $log, MagazineAPI, api_url, public_url, $q, $timeout, $window, HAImageService, $state, $interval, debounce, MagazineControlService, PhantomJs, MetaData, $location, $filter) {
    var Magazine = function(identifier, options) {
        this.rawData =          null;               //raw data for the magazine
        this.identifier =       identifier;         //magazine slug or id
        this.imageQuality =     "desktop";          //the image quality being loaded (mobile, tablet, desktop, full)
        this.imageBaseUrl =     api_url + "magazine/" + identifier + "/image/";         // Image API path
        this.thumbnailBaseUrl = api_url + "magazine/" + identifier + "/thumbnail/";     // Image API path
        this.imagePublicPrefix = public_url;                                            // Prefix to the public folder for images
        this.requiredPreloads = [];             //images to preload before mag ready
        this.asyncPreloads =    [];             //images to start loading while preparing mag
        this.videoPlayers =     {};             //list of all video players, indexed by their ID

        //this.coverPage = null;          //processed cover page (ref from raw data)  @TODO remove, obsolete
        this.pages = undefined;                //processed page data, with template instructions etc (undefined to allow for bind-once)
        this.landscapePages = undefined;       //processed page data paired into landscape pages (obj as {left: page, right: page})
        this.flkty = null;
        this.isPortrait = $rootScope.isPortrait;
        this.dragEnabled = true;            //whether or not drag is currently enabled
        this.autoPlay = false;              //whether or not magazine autoPlay enabled
        this.hdModeEnabled = false;         //when enabled higher quality content is loaded
        this.mobileVideoMode = false;       //when true will disable vimeo experimental background video and other features
        this.isReady = false;               //true when magazine is ready
        this.isLocked = false;              //true when magazine is protected and locked
        this.notFound = false;              //true when magazine is not found (i.e. 404 from load)
        this.reflowInProgress = false;      //true when a reflow is currently happening
        this.fullScreenElement = null;      //holds the element currently in fullscreen
        this._pauseKeyboardNavigate = false;

        this.$$runPromise = null;       //promise, resolves to true when run complete
        this.$$reflowPromise = null;    //promise, resolves to true when reflow complete
        this.$$autoPlayPromise = null;  //promise, used to cancel the autoplay interval

        //this.pageIndexOffset = 1;       //offset to account for difference between standard mag pages (pages array) and actual pages in flickity
        //this.currentPageIndex = 0;      //index of current page in pages array (i.e. excludes cover page)
        this.currentPageNumber = 0;           //current display page as per the page number of the current page (left-most page in landscape mode)
        this.lastPageNumber = 0;              //previous page number (left-most page in landscape mode)
        this.currentSpreadPageNumbers = [];   //current spread page numbers
        this.currentPortraitIndex = 0;  //index of current page in pages array (is also the current flickity index, won't match page number display due to cover/empty pages)
        this.currentLandscapeIndex = 0; //index of current page group in landscape pages array
        this.totalPages = undefined;    //total pages in magazine (display only, undefined to allow for bind-once)
        this.pageOffset = 0;            //offset between page numbers and their page index

        this.display = {
            showContents: false,          //display the table of contents
            showContentsWithToolbar: false
        };
        this.video = {
            isPlaying: false,
            isLoading: false,
            loadProgress: 0,
            playProgress: 0,
            isMuted: false,
            videoModeUI: false
        };

        this.config = {
            elmSelector: '.flkty-magazine',
            uiControlSelector: '.mag-control',  //element selector for magazine control UI elements
            startPage: 'contents',      //starting page: 'contents' or numeric page number as displayed on page
            pagePreload: 3,             //number of spread pages to preload before/after current page
            useImageAPI: true,          //load images from API rather than public
            autoPlayTime: 3500,         //autoPlay time in ms
            globalOvercrop: false,      //use the global overcrop detection for deciding if image is 'cover' or 'contain'

            navClickZones: 0.2,         //size of clickable zones on left/right side that navigate the mag (as percentage, 0.0 - 1.0)

            onPageChange: angular.noop, //fn called on page change.
                                        //current page(s) are passed to the callback: {currPage: obj, nextPage: obj}

            debugMode: false,           //enable debuging messages
            debugVerbosity: 3           //level of debug messages, higher number = more messages
        };
        this.optimisations = {
            delayPreloading: 0,         //delay image preloading, for best performance results set to trigger after animations while user is reading
            delayUrlUpdate: 0,          //delay updating URL with page, for best performance results set to trigger after animations
            throttlePageChange: 800,    //throttle page change events with a debounce (pageset loading, callback)
            delayControlColorUpdate: 0, //delay updating magazine UI controls' color
            disableControlColorUpdate: false,
            videoPreload: true,         //preload video as soon as player added to DOM (can cause excessive data usage and slow down mag a bit, but has videos ready to play when reached)
            noSpreadImages: false,      //doesn't load the full spread images and instead uses just the left and right partials
            pageLookAhead: 50,          //how many pages on either side of the current to have in the DOM (just the container element is present otherwise)
            useWebworkers: false,       //offload certain work to webworkers to not block main thread and interrupt animations

        };

        //set the passed in configuration
        if (options) {
            this.setOptions(options);
        }

        var imageServiceOpts = {
            useHttp: false          //using http to preload images allows for cancellable requests (xhr requests are slower than normal image resource requests)
        };
        this.HAImageService = new HAImageService(imageServiceOpts); //image service, used to pre-load images

        //image preload stack, for control over xhr requests
        this.preloadStack = [];

        this.eventHandlers = {};    //key value of events and their handler function ('event': fn), used for unbinding when destryoing mag
    };

    /**
     * set config options, overriding default values
     * @param {obj} options     key:value object of config options
     */
    Magazine.prototype.setOptions = function(options) {
        if (typeof(options)=="object"){
            angular.extend(this.config, options);

            if (typeof this.config.onPageChange != "function") {
                $log.error("onPageChange callback not a function");
                this.config.onPageChange = angular.noop;
            }
        } else {
            $log.error("invalid options: not an object");
        }
    };

    /**
     * set optimisation options, overriding default values
     * @param {obj} optimisations     key:value object of optimisation options
     */
    Magazine.prototype.setOptimisations = function(optimisations) {
        if (typeof(optimisations)=="object"){
            angular.extend(this.optimisations, optimisations);
        } else {
            $log.error("invalid options: not an object");
        }
    };

    /**
     * create an array of all the magazine control UI elements
     * @return {array} array of angular elements for mag control UI
     */
    Magazine.prototype.discoverUIControls = function() {
        var self = this;

        // create an array of all the magazine control UI elements
        var elmArray = [].slice.call(document.querySelectorAll(self.config.uiControlSelector));    //cast from nodelist to array
        self.magControls = elmArray.map(function(elm, index) {
            return angular.element(elm);
        });
    };

    /**
     * detect the optimal image quality level
     * @return {string} the quality level string
     */
    Magazine.prototype.detectQualityLevel = function() {
        var self = this;

        //@TODO
        //use screen res combined with pixel ratio
        //...
        return "desktop";
    };

    /**
     * sets the image quality to be used when pages are created
     * @param {string} quality  desired quality as a string (mobile, tablet, desktop, full)
     */
    Magazine.prototype.setImageQuality = function(quality) {
        var self = this;

        var qualityLevels = {'mobile': null, 'tablet': null, 'desktop': null, 'full': null};
        if (quality in qualityLevels) {
            self.imageQuality = quality;
        } else {
            console.error('Magazine: cannot set quality level. [' +quality+'] not valid option');
        }

        if (self.isReady && !self.runInProgress) {
            //run not in process ad magazine already initialised - reload the page images
            self.resetPageImages();
        }
    };


    /**
     * load magazine data from API, either by magazine slug or id
     * @param  {str/int} identifier magazine slug or id
     * @return {promise}            promise resolves to API data when http request complete
     */
    Magazine.prototype.load = function(identifier, password) {
        var self = this;
        self.notFound = false;
        self.isLocked = false;

        if (isNaN(identifier)) {
            self.debug('load magazine by slug: '+identifier, 'debug', 3);
            self.rawData = MagazineAPI.getProtectedBySlug({slug: identifier, password: password});
        } else {
            self.debug('load magazine by id: '+identifier, 'debug', 3);
            self.rawData = MagazineAPI.getProtected({id: identifier, password: password});
        }

        self.rawData.$promise.catch(function(err) {
            if (err.status && err.status == 404) {
                self.notFound = true;
            } else if (err.status && err.status == 403) {
                self.isLocked = true;
            }

            return $q.reject(err);
        });

        return self.rawData.$promise;
    };

    /**
     * process the page data
     * @return {promise} promise resolved when complete
     */
    Magazine.prototype.createPages = function() {
        var self = this;

        self.pages = [];
        self.landscapePages = [];
        self.pageOffset = 0;        //keep track of how much page number are offset from their index (NB: won't work if empty pages are anyway past the first landscape page)
        self.totalPages = 0;

        //cancel any exsiting preloads and clear promise stacks
        self.clearAllPreloadStacks();

        //set the image url prefixes according to quality level
        var imagePrefix = "";
        if (self.hdModeEnabled) {
            imagePrefix = "full";
        } else {
            imagePrefix = self.imageQuality;
        }

        //generate the image urls, and add thumbnails to preloaders
        self.rawData.pages.forEach(function(page, index) {

            // prepare meta data for page
            // fallback to page title and desc if no specific meta title and desc given (strip html tags from each)
            page.meta = page.meta || {};
            page.meta.title = page.meta.title || (page.title && page.title.replace(/<\/?[^>]+>/gi, ' '));
            page.meta.description = page.meta.description || (page.desc && page.desc.replace(/<\/?[^>]+>/gi, ' '));
            // if still no suitable page/description, delete them so the default can be used instead
            if (!page.meta.title) {
                delete page.meta.title;
            }
            if (!page.meta.description) {
                delete page.meta.description;
            }
            page.meta = angular.extend({}, MetaData.defaults, page.meta);

            // prepare image preloaders
            if (page.image) {
                page.isSpread = false;

                if (self.config.useImageAPI) {
                    //load image from API
                    page.image.url = self.imageBaseUrl + page.image.id + "?quality="+imagePrefix;
                    page.image.thumbnail_url = self.thumbnailBaseUrl + page.image.id;
                    page.image.preload_url = self.thumbnailBaseUrl + page.image.id + "?blurred=true";
                } else {
                    //load image from public folder
                    page.image.url = self.imagePublicPrefix + page.image.base_path + imagePrefix + "-" + page.image.diskname;
                    page.image.thumbnail_url = self.imagePublicPrefix + page.image.base_path + "thumbnail-" + page.image.diskname;
                    page.image.preload_url = self.imagePublicPrefix + page.image.base_path + "preload-" + page.image.diskname;
                }

                // set image to meta
                page.meta.images = [("http://" + $location.host() + "/" + page.image.thumbnail_url)];

                if (!PhantomJs.isPrerendering) {
                    // self.requiredPreloads.push(page.image.preload_url);
                    // self.asyncPreloads.push(page.image.thumbnail_url);
                }
            }
            if (page.spread_image) {
                page.isSpread = true;

                if (self.config.useImageAPI) {
                    //load image from API
                    page.spread_image.url = self.imageBaseUrl + page.spread_image.id + "?quality="+imagePrefix;
                    page.spread_image.thumbnail_url = self.thumbnailBaseUrl + page.spread_image.id;
                    page.spread_image.preload_url = self.thumbnailBaseUrl + page.spread_image.id + "?blurred=true";
                } else {
                    //load image from public folder
                    page.spread_image.url = self.imagePublicPrefix + page.spread_image.base_path + imagePrefix + "-" + page.spread_image.diskname;
                    page.spread_image.thumbnail_url = self.imagePublicPrefix + page.spread_image.base_path + "thumbnail-" + page.spread_image.diskname;
                    page.spread_image.preload_url = self.imagePublicPrefix + page.spread_image.base_path + "preload-" + page.spread_image.diskname;
                }

                // set spread image to meta (replacing single image)
                page.meta.images = [("http://" + $location.host() + "/" + page.spread_image.thumbnail_url)];

                if (self.optimisations.noSpreadImages) {
                    //remove the spread image and spread_image_id from the page data, but keep for meta
                    page.spread_image = null;
                    page.spread_image_id = null;
                } else if (!PhantomJs.isPrerendering) {
                    // self.requiredPreloads.push(page.spread_image.preload_url);
                    // self.asyncPreloads.push(page.spread_image.thumbnail_url);
                }
            }

            // keep track of which spread side this page belongs to
            if (index % 2 == 0) {
                page.pageSide = "left";
            } else {
                page.pageSide = "right";
            }

            //give each page a static page number
            if (page.page_type == "cover") {
                //cover page has no page number and doesn't count towards total pages
                page.pageNumber = 0;
                self.pageOffset++;

                //at the moment cover pages are always on the left
                var landscapePage = {
                    left: page,
                    right: index < self.rawData.pages.length ? self.rawData.pages[index+1] : null,
                    meta: page.meta,
                    isCover: true,
                    isSpread: page.isSpread,
                    pageIndex: index,
                    pageNumber: 0
                };
                self.landscapePages.push(landscapePage);

                self.pages.push(page);
            } else if (page.page_type == "empty") {
                // empty page's have no page number and don't count towards total pages
                // they are rendered in landscape mode though, so must still be added to the pages array
                page.pageNumber = 0;
                self.pageOffset++;

                if (index % 2 == 0) {
                    var landscapePage = {
                        left: page,
                        right: index < self.rawData.pages.length ? self.rawData.pages[index+1] : null,
                        pageIndex: index,
                        pageNumber: index-self.pageOffset+1
                    };
                    self.landscapePages.push(landscapePage);
                }

                self.pages.push(page);
            } else {
                page.pageNumber = index-self.pageOffset+1;

                //add pairs of pages to landscape page stack
                if (index % 2 == 0) {
                    var landscapePage = {
                        left: page,
                        right: index < self.rawData.pages.length ? self.rawData.pages[index+1] : null,
                        meta: page.meta,
                        isSpread: page.isSpread,
                        pageIndex: index,
                        pageNumber: index-self.pageOffset+1
                    };
                    self.landscapePages.push(landscapePage);
                }

                self.pages.push(page);
            }
        });

        self.totalPages = self.pages.length-self.pageOffset;
        return self.pages;
    };

    /**
     * resets the page image urls, used when a quality change occurs
     *
     */
    Magazine.prototype.resetPageImages = function() {
        var self = this;

        //cancel any exsiting preloads and clear promise stacks
        self.clearPreloadStack();

        //set the image url prefixes according to quality level
        var imagePrefix = "";
        if (self.hdModeEnabled) {
            imagePrefix = "full";
        } else {
            imagePrefix = self.imageQuality;
        }

        //update the image urls for the pages
        self.pages.forEach(function(page, index) {
            if (page.image) {
                if (self.config.useImageAPI) {
                    //load image from API
                    page.image.url = self.imageBaseUrl + page.image.id + "?quality="+imagePrefix;
                } else {
                    //load image from public folder
                    page.image.url = self.imagePublicPrefix + page.image.base_path + imagePrefix + "-" + page.image.diskname;
                }

                //reset page load state
                page.image.isLoaded = false;
            }
            if (page.spread_image) {
                if (self.config.useImageAPI) {
                    //load image from API
                    page.spread_image.url = self.imageBaseUrl + page.spread_image.id + "?quality="+imagePrefix;
                } else {
                    //load image from public folder
                    page.spread_image.url = self.imagePublicPrefix + page.spread_image.base_path + imagePrefix + "-" + page.spread_image.diskname;
                }

                //reset page load state
                page.spread_image.isLoaded = false;
            }

            //reset page load state
            page.imagesLoaded = false;
            if (page.$$pageLoadedPromise) {
                //page.$$pageLoadedPromise.reject('reloading for HD toggle');   //@TODO should find a way to cancel the promise here
                page.$$pageLoadedPromise = null;
            }
        });

        //reload the current page group
        var promises = [];
        if (self.currentPortraitIndex == 0) {
            for (var i=self.currentPortraitIndex; i<self.config.pagePreload*2; i++) {
                promises.push(self.preloadPage(i));
            }
            return $q.all(promises);
        } else {
            return self.preloadPageSet(self.config.pagePreload);
        }
    };

    /**
     * create a new flickity object for the magazine (private)
     * @param  {str} elmSelector    element selector to use to create the flickity
     * @return {obj}                flickity object
     */
    Magazine.prototype.createFlickityMagazine = function(elmSelector) {
        var self = this;

        if (self.flkty) {
            //@TODO seems to be issues with DOM manipulation when using .destroy()
            //self.flkty.destroy();
            self.flkty = null;
        }

        var flkty = new Flickity(elmSelector, {
            cellAlign: 'left',
            cellSelector: '.page',
            selectedAttraction: 0.05,   //higher attraction makes the slider move faster
            friction: 0.5,              //higher friction makes the slider feel stickier and less bouncy
            percentPosition: true,
            prevNextButtons: false,
            pageDots: false,
            accessibility: false,
            wrapAround: false,
            groupCells: '100%',         // now supported natively! yay - we can even select a cell within a group (called slides)
            dragThreshold: 10,          // also now supported natively, though doesn't "lock" scrolling when scrolling text

            flickDistanceThreshold: 20, //add a threshold to how far the user has to flick for it to register (0 for original behaviour) (best to use lower in portrait mode)
            flickDeltaThreshold: 2.5,   //add a threshold to how fast the user has to flick for it to register (0 for original behaviour)
            flickDeltaOverride: 10,     //add an override velocity - if the user flicks hard enough it will ignore the distance setting
                                        //@TODO maybe don't use, seems to be a bad delta calculation when vertically scrolling an child element
        });

        return flkty;
    };

    Magazine.prototype.handleKeyDown = function(event) {
        var self = this;

        if (!self._pauseKeyboardNavigate && (event.keyCode == 37 || event.keyCode == 39)) {
            $rootScope.$evalAsync(function() {
                self._pauseKeyboardNavigate = true;   //wait until key up to call again

                if (event.keyCode == 37 && !self.display.showContents) {
                    self.prevPage();
                } else if (event.keyCode == 39 && !self.display.showContents) {
                    self.nextPage();
                }
            });
        }
    };
    Magazine.prototype.handleKeyUp = function(event) {
        var self = this;
        self.debug("[keypress] keyCode: "+event.keyCode, 'debug', 3)

        if (self._pauseKeyboardNavigate && (event.keyCode == 37 || event.keyCode == 39)) {
            $rootScope.$evalAsync(function() {
                self._pauseKeyboardNavigate = false;
            });
        }

        // space bar - toggle video
        if (event.keyCode == 32) {
            $rootScope.$evalAsync(function() {
                self.toggleVideoPlay();
            });
        }

        // m key - mute/unmute
        if (event.keyCode == 77) {
            $rootScope.$evalAsync(function() {
                self.toggleVideoAudio();
            });
        }
    };

    /**
     * create event handlers for the flickity magazine instance
     */
    Magazine.prototype.createFlickityHandlers = function() {
        var self = this;

        if (!self.flkty) {
            throw new Error('No flickity instance');
        }

        self.flkty.on('cellSelect', function() {
            $rootScope.$evalAsync(function() {
                self.debug('[Flickity] cellSelect event', 'debug', 3);

                self.debug('[Flickity] lastFlktyIndex: '+self.lastFlktyIndex+', new index: '+self.flkty.selectedIndex, 'debug', 3);
                // update page regardless
                self.updateCurPage();

                //only trigger if selected cell has been changed
                if (self.lastFlktyIndex != self.flkty.selectedIndex) {
                    //preload page set
                    self.preloadPageSet(self.config.pagePreload);

                    //stop videos from playing if spread page changed
                    if (self.currentSpreadPageNumbers.indexOf(self.lastPageNumber) === -1) {
                        self.stopAllVideo();
                    }

                    //update UI control colors
                    if (!self.optimisations.disableControlColorUpdate) {
                        $timeout(function() {self.updateControlColors()}, self.optimisations.delayControlColorUpdate);
                    }

                    //call the onPageChange callback
                    if (self.isPortrait) {
                        var callbackData = {
                            left: self.getCurrentPage(),
                            right: self.getNextPage()
                        };
                    } else {
                        var callbackData = self.landscapePages[self.currentLandscapeIndex];
                    }
                    self.config.onPageChange(callbackData);
                }

                // update the last index
                self.lastFlktyIndex = self.flkty.selectedIndex;
                self.lastPageNumber = self.currentPageNumber;
            });
        });
        self.flkty.on('settle', function(event, pointer) {
            $rootScope.$evalAsync(function(event, pointer) {
                self.debug('[Flickity] settle event', 'debug', 3);
            });
        });
        self.flkty.on('dragStart', function(event, pointer) {
            $rootScope.$evalAsync(function(event, pointer) {
                self.debug('[Flickity] dragStart event', 'debug', 3);

                //stop autoplay
                self.toggleAutoplay(false);
            });
        });
        self.flkty.on('dragEnd', function(event, pointer) {
            $rootScope.$evalAsync(function() {
                self.debug('[Flickity] dragEnd event', 'debug', 3);

            });
        });
        //Triggered when the user's pointer is pressed and unpressed and has not moved enough to start dragging.
        self.flkty.on('staticClick', function(event, pointer, cellElement, cellIndex) {
            $rootScope.$evalAsync(function() {
                self.debug('[Flickity] staticClick event', 'debug', 3);

                //cancel if the event tells us not to handle static click
                if (event.noStaticClick) {
                    self.debug('[Flickity] staticClick event prevented on element', 'debug', 3);
                    return false;
                }

                //cancel if the target element is a control or clickable object
                if ("ng-click" in event.target.attributes || "href" in event.target.attributes) {
                    self.debug('[Flickity] staticClick event on element with ng-click', 'debug', 3);
                    return false;
                }



                /*---allow left and right click on video pages when not using vimeo
                //determin if the left or right page has video
                var landscapePair = self.getCurrentPage(true);
                if (landscapePair.left.video || landscapePair.left.spread_video || landscapePair.right.video || landscapePair.right.spread_video) {
                    self.debug('[Flickity] staticClick event on page with video', 'debug', 3);
                    return false;
                }
                */

                if (self.config.navClickZones>0) {
                    //check if the click was in a navigation click zone (invisible areas on left/right side of mag)
                    var zoneSize = $window.innerWidth*self.config.navClickZones;
                    var clickPoint = pointer.pageX;
                    if (clickPoint <= zoneSize) {
                        self.toggleAutoplay(false);
                        self.prevPage();
                    } else if (clickPoint >= $window.innerWidth-zoneSize) {
                        self.toggleAutoplay(false);
                        self.nextPage();
                    } else {
                        //default to toggling any video on page (if using vimeo)
                        // self.toggleVideoPlay();
                    }
                } else {
                    //clicking on a cell toggles any video for the current page (if using vimeo)
                    // self.toggleVideoPlay();
                }
            });
        });

        //save a ref to the event handler so we can remove it later
        self.eventHandlers['keydown'] = self.handleKeyDown.bind(self);
        self.eventHandlers['keyup'] = self.handleKeyUp.bind(self);
        $window.addEventListener('keydown', self.eventHandlers['keydown']);
        $window.addEventListener('keyup', self.eventHandlers['keyup']);
    };

    /**
     * remove previously created event handlers for mag control
     */
    Magazine.prototype.removeFlickityHandlers = function() {
        var self = this;

        $window.removeEventListener('keydown', self.eventHandlers['keydown']);
        $window.removeEventListener('keyup', self.eventHandlers['keyup']);
    };


    Magazine.prototype.onVideoFirstFrame = function(player_id, data) {
        var self = this;

        $rootScope.$evalAsync(function() {
            self.videoPlayers[player_id].hasBeenPlayed = true;  //after the first play we don't want to hide iframe anymore (mobile)
            self.debug("video id:" + player_id + " event: first frame", 'debug', 3);
        });
    };
    Magazine.prototype.onVideoPlay = function(player_id, data) {
        var self = this;

        $rootScope.$evalAsync(function() {
            //self.video.isPlaying = true;
            // hide text while video playing
            MagazineControlService.display.pageContent = false;
            self.videoPlayers[player_id].hasBeenPlayed = true;  //after the first play we don't want to hide iframe anymore (mobile)

            // enable special UI features while playing video
            self.toggleVideoModeUI(true);
            self.debug("video id:" + player_id + " event: play", 'debug', 3);
        });
    };

    Magazine.prototype.onLoadProgress = function(player_id, data) {
        var self = this;

        $rootScope.$evalAsync(function() {
            self.video.isLoading = true;
            self.video.loadProgress = data;
        });
    };

    Magazine.prototype.onPlayProgress = function(player_id, data) {
        var self = this;

        $rootScope.$evalAsync(function() {
            self.video.isPlaying = true;
            self.videoPlayers[player_id].hasBeenPlayed = true;
            self.video.playProgress = data;
        });
    };

    Magazine.prototype.onVideoPause = function(player_id, data) {
        var self = this;

        $rootScope.$evalAsync(function() {
            self.video.isPlaying = false;
            // return text when video paused
            MagazineControlService.display.pageContent = true;
            self.videoPlayers[player_id].hasBeenPlayed = true;
            self.debug("video id:" + player_id + " event: pause", 'debug', 3);
        });
    };

    Magazine.prototype.onVideoFinish = function(player_id) {
        var self = this;

        $rootScope.$evalAsync(function() {
            self.video.isPlaying = false;
            MagazineControlService.display.pageContent = true;

            //bring back normal UI colors
            self.toggleVideoModeUI(false);

            // if(!self.mobileVideoMode) {
            //     self.videoPlayers[player_id].stop(); // note that iphone seems to have a bug with unloading videos
            // }

            // @TODO - causes the vimeo video player to crash on mobile, can only do this on desktop
            // $f(player_id).api('unload');    //reset the video


            self.debug("video id:" + player_id + " event: finish", 'debug', 3);
        });
    };

    Magazine.prototype.onVideoIdle = function(player_id) {
        var self = this;

        $rootScope.$evalAsync(function() {
            self.video.isPlaying = false;
            MagazineControlService.display.pageContent = true;
            //bring back normal UI colors
            self.toggleVideoModeUI(false);
            self.debug("video id:" + player_id + " event: finish", 'debug', 3);
        });
    };

    /**
     * checks if video is playing (sometimes the video state will become out of sync with the isPlaying state in the magazine)
     * @param  {integer}  player_id     a specific player to check (otherwise all players are checked)
     * @return {promise}                resolves to true if video is playing (will update self.video.isPlaying as well)
     */
    Magazine.prototype.isVideoPlaying = function(player_id) {
        var self = this;
        var deferred = $q.defer();

        if (typeof player_id == "undefined") {
            var promises = [];

            //go through each video player and check paused status
            angular.forEach(self.videoPlayers, function(player, index) {
                var playerDeferred = $q.defer();
                player.api('paused', function(value, player_id) {
                    playerDeferred.resolve(value);
                });

                promises.push(playerDeferred.promise);
            });

            //resolve the promise when all players have been checked
            $q.all(promises).then(function(results) {
                //reduce the results into a single boolean value
                self.video.isPlaying = results.reduce(function(prev, current) {
                    return prev && !current;
                }, self.video.isPlaying);

                deferred.resolve(self.video.isPlaying);
            });
        } else {
            //get the specific player's status
            var player = $f(player_id);
            try {
                player.api('paused', function(value, player_id) {
                    self.video.isPlaying = self.video.isPlaying && !value;
                    deferred.resolve(self.video.isPlaying);
                });
            } catch (e) {
                //error happened...probably player wasn't found
                deferred.reject(e);
            }
        }

        return deferred.promise;
    };

    /**
     * get the video player for the given page
     * @param  {obj} page       either a landscape page pair or a single portrait page
     */
    Magazine.prototype.getPageVideoPlayer = function(magazinePage) {
        var page;

        // check if landscape pair and determin if the left or right page has a video
        if ('left' in magazinePage) {
            if (landscapePair.left.video || landscapePair.left.spread_video) {
                page = landscapePair.left;
            } else if (landscapePair.right.video || landscapePair.right.spread_video) {
                page = landscapePair.right;
            } else {
                return null;
            }
        } else {
            page = magazinePage;
        }

        // return the video player (just jwplayer supported for now)
        return page.spread_video.jwplayer;
    }

    /**
     * start or stop the video for the current page
     * @param  {bool} force explicitly start or stop the video
     */
    Magazine.prototype.toggleVideoPlay = function(force, event) {
        var self = this;
        var landscapePair = self.getCurrentPage(true);

        //determin if the left or right page has video
        if (landscapePair.left.video || landscapePair.left.spread_video) {
            var page = landscapePair.left;
        } else if (landscapePair.right.video || landscapePair.right.spread_video) {
            var page = landscapePair.right;
        } else {
            self.debug("magazine: no video to play", 'debug', 3);
            return false;
        }

        page.$$pageLoadedPromise.then(function(data) {
            if (page.spread_video.jwplayer) {
                var player = page.spread_video.jwplayer;
                if (typeof force == "undefined") {
                    player.play();
                } else {
                    player.play(force);
                }
            } else if (page.spread_video.froogaloop) {
                /*--------OLD VIMEO CODE--------*/
                var player = page.spread_video.froogaloop;
                var command = 'play';

                // re-check the player's status before sending the command
                self.isVideoPlaying(player.element).then(function() {
                    if (typeof force == "undefined") {
                        command = self.video.isPlaying ? 'pause' : 'play';
                    } else {
                        command = force ? 'play' : 'pause';
                    }

                    self.debug('video command: ' + command, 'debug', 2);
                    player.api(command);
                });
            }
        });
    };

    /**
     * stop any video that might be playing
     */
    Magazine.prototype.stopAllVideo = function() {
        var self = this;

        //go through each video player and send a stop command
        self.debug("video command: stop all", 'debug', 3);
        angular.forEach(self.videoPlayers, function(player, player_id) {
            if (player_id.indexOf("jw") != -1 && typeof player != "undefined") {
                // jwplayer
                // if(self.mobileVideoMode) {
                //     player.play(false); // either use stop() to reset video or play() to pause (keeps on loading)
                //     self.toggleVideoModeUI(false);
                // } else {
                //     player.stop(); // note that iphone seems to have a bug with unloading videos
                // }
                // @TODO watch this - for now we will use this feature instead of above, but if video causes crashed on mobile
                // we need to re-enable the above code
                player.stop();
            } else if (typeof player != "undefined") {
                // vimeo player
                player.api('pause');
            }
        });
    };

    /**
     * toggle the display of special UI colors for better visible mag controls
     * @param  {bool} force explicitly set the video mode UI on or off
     */
    Magazine.prototype.toggleVideoModeUI = function(force) {
        var self = this;

        self.magControls.forEach(function(elm, index) {

            if (typeof force == "undefined") {
                self.video.videoModeUI = !self.video.videoModeUI;
            } else {
                self.video.videoModeUI = !!force;
            }

            if (self.video.videoModeUI) {
                elm.addClass('video-mode');
            } else {
                elm.removeClass('video-mode');
            }
        });

        self.debug('Video mode UI toggled: ' + self.video.videoModeUI, 'debug', 3);
    };

    /**
     * toggle the audio for the currently page's video
     * @param  {bool} force explicitly set the audio to on or off
     */
    Magazine.prototype.toggleVideoAudio = function(force) {
        var self = this;
        var landscapePair = self.getCurrentPage(true);

        //determin if the left or right page has video
        if (landscapePair.left.video || landscapePair.left.spread_video) {
            var page = landscapePair.left;
        } else if (landscapePair.right.video || landscapePair.right.spread_video) {
            var page = landscapePair.right;
        } else {
            self.debug("magazine: no video to play", 'debug', 3);
            return false;
        }

        if (!page.video && !page.spread_video) {
            self.debug("magazine: no video to mute/unmute", 'debug', 3);
            return false;
        }

        page.$$pageLoadedPromise.then(function(data) {
            var player = page.spread_video.froogaloop;

            if (typeof force == "undefined") {
                self.video.isMuted = !self.video.isMuted;
            } else {
                self.video.isMuted = !!force;
            }

            var volume = self.video.isMuted ? 0 : 1;
            player.api('setVolume', volume);

            self.debug('video command: set volume ' + volume, 'debug', 2);
        });
    };

    /**
     * mute all videos that might be playing
     */
    Magazine.prototype.muteAllVideo = function() {
        var self = this;

        //go through each video player and send a stop command
        angular.forEach(self.videoPlayers, function(player, index) {
            if (player_id.indexOf("jw") != -1 && typeof player != "undefined") {
                // jwplayer
                player.setMute(true);
            } else if (typeof player != "undefined") {
                // vimeo player
                player.api('setVolume', 0);
            }
        });
    };

    /**
     * starts/stops autoPlay feature
     * @param  {bool} force   set the state explicitly
     */
    Magazine.prototype.toggleAutoplay = function(force) {
        var self = this;
        if (typeof force == "undefined") {
            self.autoPlay = !self.autoPlay;
        } else {
            self.autoPlay = !!force;
        }

        if (self.autoPlay) {
            self.debug("starting autoplay", 'debug', 3);
            self.nextPage();

            self.$$autoPlayPromise = $interval(function() {
                self.debug("autoplay: nextpage", 'debug', 3);

                if (self.currentLandscapeIndex < self.landscapePages.length-1) {
                    self.nextPage();
                } else {
                    self.goToCover();
                }
            }, self.config.autoPlayTime);
        } else {
            self.debug("stopping autoplay", 'debug', 3);
            $interval.cancel(self.$$autoPlayPromise);
        }
    };

    /**
     * toggle the ability to drag next/prev page
     * @param  {bool} force     explicitly sets the state to enabled/disabled
     */
    Magazine.prototype.toggleDrag = function(force) {
        var self = this;

        if (this.flkty) {
            if (typeof force != "undefined") {
                self.dragEnabled = !!force;
            } else {
                self.dragEnabled = !self.dragEnabled;
            }

            if (this.dragEnabled) {
                this.flkty.emit('activate');
            } else {
                this.flkty.emit('deactivate');
            }
        }
    };


    Magazine.prototype.exitFullscreen = function() {
        var self = this;

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else {
            return false;
        }
        return true;
    };

    Magazine.prototype.enterFullscreen = function(element) {
        var self = this;

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else {
            return false;
        }
        self.fullScreenElement = element;
        self.fullScreenElement.addEventListener('fullscreenchange', function() {
            var self = this;

            self.fullScreenElement.removeAttribute('data-is-fullscreen');
            self.fullScreenElement = false;
        }.bind(self), false);

        self.fullScreenElement.setAttribute('data-is-fullscreen', 'true');
        return true;
    };

    /**
     * toggle HD mode
     * @param  {bool} force     explicitly sets the state to on/off
     */
    Magazine.prototype.toggleHD = function(force) {
        var self = this;

        if (typeof force != "undefined") {
            self.hdModeEnabled = !!force;
        } else {
            self.hdModeEnabled = !self.hdModeEnabled;
        }

        //reload the page images in the new quality
        self.resetPageImages();
    };

    Magazine.prototype.updateCurPage = function() {
        var self = this;

        if (self.isPortrait) {
            // When using automatic grouping (based on cells that fit in view) in portrait mode, we can't guarantee
            // that we will have 1 or 2 cells in a group, because of "empty" pages which are hidden.
            // So to determin the correct indexes we need to check the left-most cell in the current
            // slide and work out what page it is. From that we can determin the correct index
            var leftCell = self.flkty.selectedSlide.cells[0];
            var cellIndex = self.flkty.cells.indexOf(leftCell);

            self.currentPortraitIndex = cellIndex;
            self.currentLandscapeIndex = Math.floor(cellIndex/2);

        } else {
            var leftCell = self.flkty.selectedSlide.cells[0];
            var cellIndex = self.flkty.cells.indexOf(leftCell);

            self.currentPortraitIndex = cellIndex;
            self.currentLandscapeIndex = self.flkty.selectedIndex;
        }

        //update the current display page number
        self.currentPageNumber = self.getCurrentPage().pageNumber;
        self.debug("page numbers... display: " + self.currentPageNumber + " portrait idx: " + self.currentPortraitIndex + " landscape idx: " + self.currentLandscapeIndex, 'debug', 3);

        //update the current spread page numbers
        if (self.currentPageNumber % 2 === 0) {
            self.currentSpreadPageNumbers = [self.currentPageNumber-1, self.currentPageNumber]; 
        } else {
            self.currentSpreadPageNumbers = [self.currentPageNumber, self.currentPageNumber+1];
        }

        //update url by changing child page state
        $timeout(function() {
            if (self.display.showContents) {
                $state.go("^.page", {page: 'contents'});
            } else if (self.currentLandscapeIndex == 0) {
                $state.go("^.page", {page: 'cover'});
            } else {
                $state.go("^.page", {page: self.currentPageNumber});
            }
        }, self.optimisations.delayUrlUpdate);
    };

    /**
     * update UI button colors according to brightness of image in it's area
     * @param  {bool} keepPrevious      when true the won't be redrawn and the previously drawn image will be sampled
     */
    Magazine.prototype.updateControlColors = debounce(function(keepPrevious) {
        var self = this;

        if (PhantomJs.isPrerendering) {
            // don't run this if going thourgh the prerenderer
            return false;
        }

        if (self.runInProgress || self.reflowInProgress) {
            //reflow/run in progress, wait until finished before running
            self.debug('Control color update canceled: Reflow/Run in progress', 'debug', 2);
            return false;
        }

        self.debug('magazine: updating control colors', 'debug', 2);
        var imgURLs, elmRect, pointX, pointY;
        var imgCanvasOptions = {
            canvasW: window.innerWidth,
            canvasH: window.innerHeight,
            background: 'black',

            imgScale: 'cover',      //image sizing options: 'cover', 'contain', {float} scale
            imgCenterX: true,       //center image
            imgCenterY: true,
            imgPositionX: null,     //x position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerX)
            imgPositionY: null,     //y position for image drawing. (NB: acts like css background-position, only supports percentage, overrides centerY)
            sampleSizeX: 20,        //width of area to sample from given point
            sampleSizeY: 20,        //height of area to sample from given point

            redrawImage: false,      //optimisation: set to false to reuse previously drawn image

            enableDebug: true,      //show debugging square over point
        };

        // if the overriding overCroppedMode is enabled, set scale to contain
        if (self.config.globalOvercrop && $rootScope.overCroppedMode) {
            imgCanvasOptions.imgScale = 'contain';
        }

        if (self.isPortrait) {
            var page = self.getCurrentPage();

            if (page && page.image) {
                imgURLs = [{url: page.image.url}];

                //if cover page, set to image scale 'contain'
                if (page.page_type == 'cover') {
                    imgCanvasOptions.imgScale = 'contain';
                }

                // if spread image, need to draw image fixed to left or right depending on spread side
                if (page.image.meta.spread_partial==1) {
                    imgCanvasOptions.imgPositionX = "100%";
                } else if (page.image.meta.spread_partial==2) {
                    imgCanvasOptions.imgPositionX = "0%";
                }
            } else {
                //no image to draw, add a blank white image to the canvas
                imgURLs = [{url: null, width: null, height: null, bgColor: page.meta.bg_color || 'white'}];
            }

            if (!page) {
                $log.error('Unable to update control colors: there is no current page', err);
            }
        } else {
            var pagePair = self.getCurrentPage(true);

            //if cover page, set to image scale 'contain'
            if (pagePair && pagePair.isCover) {
                imgCanvasOptions.imgScale = 'contain';
                imgURLs = [{url: pagePair.left.image.url}];
            } else {
                imgURLs = [];
                if (pagePair && pagePair.left.image) {
                    imgURLs.push({url: pagePair.left.image.url});
                } else {
                    //add a blank white image to the canvas
                    imgURLs.push({url: null, width: null, height: null, bgColor: pagePair.left.meta.bg_color || 'white'});
                }
                if (pagePair && pagePair.right.image) {
                    imgURLs.push({url: pagePair.right.image.url});
                } else {
                    //add a blank white image to the canvas
                    imgURLs.push({url: null, width: null, height: null, bgColor: pagePair.right.meta.bg_color || 'white'});
                }
            }

            if (!pagePair) {
                $log.error('Unable to update control colors: there is no current page', err);
            }
        }

        var canvasPreDrawPromise;       //@TODO could be cleaner...
        if (keepPrevious!=true) {
            //clear any previously cached canvas image data
            self.HAImageService.clearCache();
            //draw the canvas first before begining the sampling (so that a cached version of the image can be used)
            canvasPreDrawPromise = self.HAImageService.getSampleAverage(imgURLs, 0, 0, imgCanvasOptions);
        } else {
            canvasPreDrawPromise = $q.resolve(true);
        }

        canvasPreDrawPromise.then(function() {
            self.magControls.forEach(function(elm, index) {
                var options = angular.extend({}, imgCanvasOptions);
                //get the control size and position
                elmRect = elm[0].getBoundingClientRect();
                options.sampleSizeX = elmRect.width || 25;
                options.sampleSizeY = elmRect.height || 25;
                pointX = elmRect.left;
                pointY = elmRect.top;

                //sample the image to get average brightness
                self.HAImageService.getSampleAverage(imgURLs, pointX, pointY, options)
                .then(function(results) {
                    //set the control color accordingly
                    if (results.brightness > 128) {
                        elm.addClass('dark');
                        elm.removeClass('light');
                    } else {
                        elm.addClass('light');
                        elm.removeClass('dark');
                    }
                    elm[0].dataset.brightness = results.brightness;
                });
            });

            self.debug('mag control colors updated', 'debug', 3);
        });
    }, 200);

    /**
     * preload a single image url
     * @return {promise} promise resolved when complete
     */
    Magazine.prototype.preloadImage = function(url, delay, useHttp) {
        if (PhantomJs.isPrerendering) {
            // don't run this if going thourgh the prerenderer
            return $q.resolve('phantom js prerendering');
        }
        return this.HAImageService.loadImage(url, delay, useHttp);
    };

    /**
     * request an oEmbed vimeo video
     * @return {promise} promise resolved when complete
     */
    Magazine.prototype.oembedVideo = function(url, args, delay) {
        return this.HAImageService.oembedVideo(url, args, delay);
    };

    /**
     * gets the vimeo video, adds to page and creates a player
     * @param  {obj} page
     * @return {promise}      resolves when player is ready for use
     *
     */
    Magazine.prototype.prepareVimeoPlayer = function(page) {
        var self = this;

        /*-------VIMEO VIDEO EMBED----------*/
        var vimeoVideoPromise = self.oembedVideo(page.spread_video.vimeo_url, {player_id: "vimeo_player_"+page.id}, delay);
        vimeoVideoPromise.then(function(data) {
            if (!self.mobileVideoMode) {
                data.html = data.html.replace(/&api/, "&background=1&api"); //inject the experimental "background" setting to the iframe
            }
            page.spread_video.oembed = data;
            page.spread_video.isLoaded = true;

            //when the iframe has loaded, create the Froogaloop player for it (wait 1 digest for DOM)
            $timeout(function() {
                var player_id = "vimeo_player_"+page.id;
                var playerElm = document.getElementById(player_id);
                if (!playerElm) {
                    console.error("could not find video iframe: " + player_id, data);
                    // try again after a delay
                    $timeout(function() {
                        console.info("retry video iframe: " + player_id, document.getElementById(player_id));
                        self.videoPlayers[player_id] = page.spread_video.froogaloop = $f(player_id);
                        page.spread_video.froogaloop.addEvent('ready', self.addVimeoPlayer.bind(self));
                    }, 5000);
                } else {
                    self.videoPlayers[player_id] = page.spread_video.froogaloop = $f(player_id);
                    page.spread_video.froogaloop.addEvent('ready', self.addVimeoPlayer.bind(self));
                }
            });

            self.debug('page_id '+page.id+' spread video prepared: ' + page.spread_video.vimeo_url, 'debug', 3);
        });

        return vimeoVideoPromise;
    };

    Magazine.prototype.addVimeoPlayer = function(player_id) {
        var self = this;
        var player = $f(player_id);
        //self.videoPlayers[player_id] = player;

        //pause the video straight away (the experimental "background" feature starts autoplay)
        self.debug('video player added: '+player_id, 'debug', 3);
        player.api('pause');

        //set the video to muted or not
        if (self.video.isMuted) {
            player.api('setVolume', 0);
        } else {
            player.api('setVolume', 1);
        }

        //add event handlers in next digest, to ensure player has reacted to above first
        $timeout(function() {
            //add event handlers to the player
            self.eventHandlers['vimeo_player_' + player_id + '_play'] = self.onVideoPlay.bind(self, player_id);
            self.eventHandlers['vimeo_player_' + player_id + '_pause'] = self.onVideoPause.bind(self, player_id);
            self.eventHandlers['vimeo_player_' + player_id + '_finish'] = self.onVideoFinish.bind(self, player_id);
            self.eventHandlers['vimeo_player_' + player_id + '_load_progress'] = self.onLoadProgress.bind(self, player_id);
            self.eventHandlers['vimeo_player_' + player_id + '_play_progress'] = self.onPlayProgress.bind(self, player_id);
            player.addEvent('play', self.eventHandlers['vimeo_player_' + player_id + '_play']);
            player.addEvent('pause', self.eventHandlers['vimeo_player_' + player_id + '_pause']);
            player.addEvent('finish', self.eventHandlers['vimeo_player_' + player_id + '_finish']);
            player.addEvent('loadProgress', self.eventHandlers['vimeo_player_' + player_id + '_load_progress']);
            player.addEvent('playProgress', self.eventHandlers['vimeo_player_' + player_id + '_play_progress']);
        });
    };

    Magazine.prototype.addJWPlayer = function(page) {
        var self = this;

        var player_id = "jw_player_"+page.id;

        // filter out video sources without a url
        page.spread_video.video_sources = $filter('filter')(page.spread_video.video_sources, function(source) {
            return !!source.file;
        });

        page.spread_video.jwplayer_cfg = {
            width: "100%",
            height: "100%",
            aspectratio: null,
            image: null, //"http://content.jwplatform.com/thumbs/jhxoxsAp-1920.jpg", //page.spread_image.url
            sources: page.spread_video.video_sources,
            preload: self.optimisations.videoPreload ? "auto" : "none",
            player_id: player_id,
            navClickZones: self.config.navClickZones
        };

        page.spread_video.isLoaded = true;

        // when the player has been created in the DOM by the directive, link instance to page and add to players list
        page.spread_video.$playerReady = $q.defer();
        page.spread_video.$playerReady.promise.then(function(jwplayer) {
            page.spread_video.jwplayer = jwplayer;
            self.videoPlayers[player_id] = jwplayer;

            //bind event handlers
            $timeout(function() {
                //add event handlers to the player
                self.eventHandlers['jw_player_' + player_id + '_first_frame'] = self.onVideoFirstFrame.bind(self, player_id);
                self.eventHandlers['jw_player_' + player_id + '_play'] = self.onVideoPlay.bind(self, player_id);
                self.eventHandlers['jw_player_' + player_id + '_pause'] = self.onVideoPause.bind(self, player_id);
                self.eventHandlers['jw_player_' + player_id + '_complete'] = self.onVideoFinish.bind(self, player_id);
                self.eventHandlers['jw_player_' + player_id + '_idle'] = self.onVideoIdle.bind(self, player_id);
                // self.eventHandlers['jw_player_' + player_id + '_buffer'] = self.onVideoBuffer.bind(self, player_id);
                self.eventHandlers['jw_player_' + player_id + '_play_progress'] = self.onPlayProgress.bind(self, player_id);
                jwplayer.on('firstFrame', self.eventHandlers['jw_player_' + player_id + '_first_frame']);
                jwplayer.on('play', self.eventHandlers['jw_player_' + player_id + '_play']);
                jwplayer.on('pause', self.eventHandlers['jw_player_' + player_id + '_pause']);
                jwplayer.on('complete', self.eventHandlers['jw_player_' + player_id + '_complete']);
                jwplayer.on('idle', self.eventHandlers['jw_player_' + player_id + '_idle']);
                jwplayer.on('buffer', self.eventHandlers['jw_player_' + player_id + '_buffer']);
                jwplayer.on('time', self.eventHandlers['jw_player_' + player_id + '_play_progress']);
            });

        });

        self.debug('jw video player added: '+player_id, 'debug', 3);
        return $q.resolve('JW Player ready');
    };


    Magazine.prototype.relinkVimeoPlayers = function() {
        var self = this;

        self.debug('Relinking video players', 'debug', 2);
        angular.forEach(self.videoPlayers, function(player, player_id) {
            var playerElm = document.getElementById(player_id);
            if (playerElm) {
                player.init(playerElm);
            } else {
                //player no longer in DOM
                console.error('Cannot relink video player ' + player_id + ". Player iFrame missing");
            }
        });
    };

    /**
     * preload a group of images and prepare videos for a page to be ready to display
     * @param  {obj|int} page  page object or page index to preload images for
     * @param  {int} delay     delay preloading by given time in ms
     * @return {promise}       promise resolved when complete
     */
    Magazine.prototype.preloadPage = function(page, delay) {
        var self = this;

        if (typeof page != "object" && !isNaN(page)) {
            page = self.pages[page];
        }

        if (!page) {
            return $q.reject(new Error('Preload Image Error: page does not exist'));
        }

        if (page.imagesLoaded && page.videosLoaded) {
            self.debug("images & video already loaded for page_id " + page.id, 'debug', 3);
            return $q.resolve(null);
        }

        //preload image/s for the given page
        var deferred = $q.defer();
        var pagePromise;
        var spreadPromise;
        var pageVideoPromise;
        var spreadVideoPromise;

        //1. Portrait page image
        if (page.image) {
            pagePromise = self.preloadImage(page.image.url, delay, false);
            pagePromise.then(function(data) {
                page.image.isLoaded = true;
                self.debug('page_id '+page.id+' image loaded: ' + page.image.url, 'debug', 3);
            });

            //add the image request to the preload stack
            self.preloadStack.push(pagePromise);
        } else {
            pagePromise = $q.resolve('No portrait image to load');
        }

        //2. Portrait page video
        if (page.video) {
            /* -- not supported for now ---
            pageVideoPromise = self.oembedVideo(page.video.vimeo_url, {player_id: "vimeo_player_"+page.id}, delay);
            pageVideoPromise.then(function(data) {
                if (!self.mobileVideoMode) {
                    //set the iFrame player to the video
                    data.html = data.html.replace(/&player_id/, "&background=1&player_id"); //inject the experimental "background" setting to the iframe
                }
                console.log(data);
                page.video.oembed = data;
                page.video.isLoaded = true;
                self.debug('page_id '+page.id+' video prepared: ' + page.video.vimeo_url, 'debug', 3);
            });
            //add the video request to the preload stack
            //self.preloadStack.push(pageVideoPromise);     //@TODO nneed to be able to cancel this promise
            */
        } else {
            pageVideoPromise = $q.resolve('No portrait video to load');
        }

        //3. Spread image
        if (page.spread_image) {
            spreadPromise = self.preloadImage(page.spread_image.url, delay, false);
            spreadPromise.then(function(data) {
                page.spread_image.isLoaded = true;
                self.debug('page_id '+page.id+' spread loaded: ' + page.spread_image.url, 'debug', 3);
            });

            //add the image request to the preload stack
            self.preloadStack.push(spreadPromise);
        } else {
            spreadPromise = $q.resolve('No spread image to load');
        }

        //3. Spread video
        if (page.spread_video) {
            /*-------JW PLayer VIDEO EMBED----------*/
            spreadVideoPromise = self.addJWPlayer(page);

            /*-------VIMEO VIDEO EMBED----------*/
            // not using vimeo anymore, but keeping code here in case we need to return
            // spreadVideoPromise = self.prepareVimeoPlayer(page);


            //add the image request to the preload stack
            //self.preloadStack.push(spreadVideoPromise);    //@TODO nneed to be able to cancel this promise
        } else {
            spreadVideoPromise = $q.resolve('No spread image to load');
        }

        //resolve when all required images have loaded
        page.imagesLoaded = !!page.imagesLoaded;
        page.videosLoaded = !!page.videosLoaded;
        page.$$pageLoadedPromise = $q.all({
            spreadImage: spreadPromise,
            pageImage: pagePromise,
            spreadVideo: spreadVideoPromise,
            pageVideo: pageVideoPromise,
            page: $q.when(page)
        }).then(function(data) {
            //self.pages[data.pageIndex].imagesLoaded = true;   //@TODO remove
            data.page.imagesLoaded = true;
            data.page.videosLoaded = true;
            deferred.resolve(data);
            return true;
        })
        .catch(function(err) {
            //@TODO reject? or resolve anyway?
            deferred.reject(err);
            return false;
        });

        return deferred.promise;
    };

    /**
     * preload a set of pages before and after the current selected page
     * @param  {int} totalPages     number of spread pages before/after current to load
     * @return {promise}            promise resolved when all pages ready
     */
    Magazine.prototype.preloadPageSet = function(totalPages) {
        var self = this;
        var promises = [];

        //experiment----------------------------
        //cancel all previous requests
        self.clearPreloadStack();

        //preload images for current spread page
        promises.push(self.preloadPage(self.currentPortraitIndex, self.optimisations.delayPreloading));
        promises.push(self.preloadPage(self.currentPortraitIndex+1, self.optimisations.delayPreloading));

        //preload prev/next pages
        for (var i=1; i<=totalPages*2; i++) {
            promises.push(self.preloadPage(self.currentPortraitIndex-i, self.optimisations.delayPreloading));
            promises.push(self.preloadPage(self.currentPortraitIndex+1+i, self.optimisations.delayPreloading));
        }

        return $q.all(promises);
    };

    /**
     * cancels any pending http image requests and clears preload stack
     */
    Magazine.prototype.clearPreloadStack = function() {
        var self = this;
        self.debug('canceling preloads and clearing stack: ' + self.preloadStack.length, 'debug', 3);
        self.preloadStack.forEach(function(promise, index) {
            self.HAImageService.cancel(promise);
        });
        self.preloadStack = [];
    };

    /**
     * cancels any pending http image requests and clears all the preload stacks
     */
    Magazine.prototype.clearAllPreloadStacks = function() {
        var self = this;

        //required preload stack
        self.debug('canceling required preloads and clearing stack: ' + self.requiredPreloads.length, 'debug', 3);
        self.requiredPreloads.forEach(function(promise, index) {
            self.HAImageService.cancel(promise);
        });
        self.requiredPreloads = [];

        //async preload stack
        self.debug('canceling async preloads and clearing stack: ' + self.asyncPreloads.length, 'debug', 3);
        self.asyncPreloads.forEach(function(promise, index) {
            self.HAImageService.cancel(promise);
        });
        self.asyncPreloads = [];

        //main preload stack
        self.debug('canceling main preloads and clearing stack: ' + self.preloadStack.length, 'debug', 3);
        self.preloadStack.forEach(function(promise, index) {
            self.HAImageService.cancel(promise);
        });
        self.preloadStack = [];
    };

    Magazine.prototype.getPrevPage = function(landscapePair) {
        if (landscapePair) {
            return this.currentLandscapeIndex <= 0 ? null : this.landscapePages[this.currentLandscapeIndex - 1];  //@TODO be careful of using currentLandscapeIndex
        } else {
            return this.currentPortraitIndex <= 0 ? null : this.pages[this.currentPortraitIndex - 1];
        }
    };

    Magazine.prototype.getCurrentPage = function(landscapePair) {
        if (landscapePair) {
            return this.landscapePages[this.currentLandscapeIndex];
        } else {
            // NB: when in portrait mode, empty pages don't get rendered
            return this.pages[this.currentPortraitIndex];
        }
    };

    Magazine.prototype.getNextPage = function(landscapePair) {
        if (landscapePair) {
            return this.currentLandscapeIndex >= this.landscapePages.length ? null : this.landscapePages[this.currentLandscapeIndex + 1];
        } else {
            return this.currentPortraitIndex >= this.pages.length ? null : this.pages[this.currentPortraitIndex + 1];
        }
    };

    /**
     * toggle the magazine between portrait and landscape
     * @param  {bool} force     explicitly set the magazine in or out of portrait mode
     * @return {bool}           returns the updated result
     */
    Magazine.prototype.togglePortraitMode = function(force) {
        var self = this;

        if (typeof force == "undefined") {
            self.isPortrait = !self.isPortrait;
        } else {
            self.isPortrait = !!force;
        }

        if (self.flkty) {
            // get the current cell
            var currentCell = self.flkty.currentCell;
            var currentCellIndex = self.flkty.cells.indexOf(currentCell);

            // wait for css to update sizes
            $timeout(function() {
                //resize flickity for any empty pages that have been removed in portrait mode
                self.flkty.resize();

                // instantly select the portrait cell (which will get us to the correct place regardless of grouping)
                var isWrapped = false;
                var isInstant = true;
                self.flkty.selectCell(self.currentPortraitIndex, isWrapped, isInstant);
            }, 50);
        }

        return self.isPortrait;
    };

    /**
     * navigate to previous page
     *
     */
    Magazine.prototype.prevPage = function() {
        var self = this;

        if (self.flkty) {
            /* -- got to contents page if currently on first page --
            if (self.currentPortraitIndex == 0) {
                self.goToContents();
                return false;
            }
            */

            self.flkty.previous();
        }
    };

    /**
     * navigate to next page
     *
     */
    Magazine.prototype.nextPage = function() {
        var self = this;

        if (self.flkty) {
            self.flkty.next();
        }
    };

    /**
     * navigate to a specific page (given by the page index)
     * @param  {int} pageNumber         the index of the page to go to
     * @param  {bool} cancelAutoplay    if true cancel any autoplay currently running
     *
     */
    Magazine.prototype.goToPage = function(pageNumber, cancelAutoplay) {
        var self = this;

        if (self.flkty) {
              if (cancelAutoplay) {
                //stop autoPlay
                self.toggleAutoplay(false);
            }

            // select the given page, regardless of how they are grouped
            var isInstant = false;
            var isWrapped = false;
            if (Math.abs(pageNumber - self.flkty.selectedIndex) > self.optimisations.pageLookAhead) {
                isInstant = true;
            }
            self.flkty.selectCell(pageNumber, isWrapped, isInstant);

            //hide table of contents if displayed
            self.display.showContents = false;
        }
    };

    /**
     * navigate to first page (i.e. cover page)
     *
     */
    Magazine.prototype.firstPage = function() {
        this.goToPage(0);

        //set url to 'cover'
        $state.go("^.page", {page: "cover"});
    };

    /**
     * navigate to last page
     *
     */
    Magazine.prototype.lastPage = function() {
        this.goToPage(this.pages.length-1);
    };

    /**
     * go to the magazine cover page
     *
     */
    Magazine.prototype.goToCover = function() {
        this.goToPage(0);
    };

    /**
     * go to the magazine contents page
     *
     */
    Magazine.prototype.goToContents = function(bgPageIndex, showToolbar) {
        var self = this;

        //stop previous videos from playing
        self.stopAllVideo();

        self.display.showContents = true;

        if (showToolbar) {
            self.display.showContentsWithToolbar = true;
        }

        //update url by changing child page state
        $state.go("^.page", {page: "contents"});
    };

    /**
     * go to the magazine contents page
     *
     */
    Magazine.prototype.closeContents = function() {
        this.display.showContents = false;
        this.updateCurPage();
    };

    /**
     * load, create and run the magazine
     * @return {promise} promise with progress update for each step in creating magazine
     */
    Magazine.prototype.run = function(password) {
        var self = this;
        if (self.runInProgress) {
            //run still in progress
            return self.$$runPromise;
        }
        if (self.reflowInProgress) {
            //reflow in progress, wait till it's done before continuing
            self.debug('Run deferred: Reflow in progress', 'debug', 2);
            return self.$$reflowPromise.then(function() {
                return self.run();
            });
        }
        var deferred = $q.defer();
        self.isReady = false;
        self.runInProgress = true;

        //load magazine
        $q.when(true)
        .then(function() {
            deferred.notify('loading magazine data');
            return self.load(self.identifier, password);
        })
        .then(function() {
            deferred.notify('detecting optimimal quality');
            var quality = self.detectQualityLevel();
            self.debug("Magazine: quality set to " + quality, 'debug', 2);
            return self.setImageQuality(quality);
        })
        .then(function() {
            deferred.notify('setting default metadata');
            var basicMetadata = {
                title: self.rawData.name,
                description: self.rawData.desc
            };

            MetaData.setDefaults(basicMetadata);
        })
        .then(function() {
            deferred.notify('creating pages');
            return self.createPages();
        })
        .then(function() {
            deferred.notify('preloading pages');
            //do initial preloading for magazine pages
            var promises = [];
            if (self.currentPortraitIndex == 0) {
                for (var i=self.currentPortraitIndex; i<self.config.pagePreload*2; i++) {
                    promises.push(self.preloadPage(i));
                }
            } else {
                self.preloadPageSet(self.config.pagePreload);
            }

            //preload cover
            //promises.push(self.preloadPage(self.coverPage));

            //don't continue until images ready
            //return $q.all(promises);

            //or load them async
            return true;
        })
        .then(function() {
            deferred.notify('preloading low-res images');
            var promises = [];
            self.requiredPreloads.forEach(function(imgUrl, index) {
                promises.push(self.preloadImage(imgUrl));
            });

            //don't continue until images ready
            //return $q.all(promises);

            //or load them async
            return true;
        })
        .then(function() {
            deferred.notify('preloading thumbnails');
            var promises = [];
            self.asyncPreloads.forEach(function(imgUrl, index) {
                promises.push(self.preloadImage(imgUrl));
            });

            //don't continue until images ready
            //return $q.all(promises);

            //or load them async
            return true;
        })
        .then(function() {
            deferred.notify('creating page controls');

            //get the UI control elements for later use
            self.discoverUIControls();

            //create flickity instance (wait for DOM to update via domReady directive)
            var unbind = $rootScope.$on("dom-ready-magazine", function(event, val) {
                deferred.notify('creating swipable pages');
                //wait +1 digest
                // @TODO 1 digest takes a long time here....need to optimise
                // due to large ng-repeat that is probably handled at this point
                $timeout(function() {
                    deferred.notify('digest cycle complete');
                    self.flkty = self.createFlickityMagazine(self.config.elmSelector);
                    self.createFlickityHandlers();

                    // Determine the first page to select (this will also trigger the page update process)
                    var startingPage = '.page-0';
                    if (isNaN(parseInt(self.config.startPage))) {
                        if (self.config.startPage == "cover") {
                            //start on cover page (page 0)
                        } else if (self.config.startPage == "contents") {
                            //display contents
                            self.display.showContents = true;
                        } else {
                            $log.error('Unknown starting page: ' + self.config.startPage);
                        }
                    } else {
                        startingPage = '.page-' + self.config.startPage;
                    }

                    deferred.notify('opening starting page: ' + startingPage);
                    var isWrapped = false;
                    var isInstant = true;
                    self.flkty.selectCell(startingPage, isWrapped, isInstant);

                    self.isReady = true;
                    self.runInProgress = false;

                    //set focus on magazine
                    document.querySelector(self.config.elmSelector).focus();

                    //update control colors
                    if (!self.optimisations.disableControlColorUpdate) {
                        $timeout(function() {self.updateControlColors()}, self.optimisations.delayControlColorUpdate);
                    }

                    deferred.resolve(true);
                });

                //cleanup the event handler
                unbind();
            });
        })
        .catch(function(error) {
            self.runInProgress = false;
            deferred.reject(error);
        });

        self.$$runPromise = deferred.promise;
        return deferred.promise;
    };

    /**
     * log debug messages to the console
     * @param  {mixed} message what to output to console
     * @param  {str} type       logging level to use (debug, info, log, warn, error)
     * @param  {int} level      verbosity level required to display this message
     */
    Magazine.prototype.debug = function(message, type, level) {
        var self = this;
        level = Math.max(level, 0);

        if (self.config.debugMode) {
            if (self.config.debugVerbosity >= level) {
                switch(type) {
                    case 'debug':
                        $log.debug(message);
                        break;
                    case 'info':
                        $log.info(message);
                        break;
                    case 'warn':
                        $log.warn(message);
                        break;
                    case 'error':
                        $log.error(message);
                        break;
                    case 'log': default:
                        //default to log
                        $log.log(message);
                        break;
                }
            }
        }
    };

    /**
     * clean up the magazine
     *
     */
    Magazine.prototype.destroy = function() {
        var self = this;

        if (self.flkty) {
            //will return the element back to its pre-initialized state.
            self.flkty.destroy();
        }

        //cancel any leftover intervals
        $interval.cancel(self.$$autoPlayPromise);

        //cancel event handlers
        self.debug('destroy key press handlers', 'debug', 3);
        angular.forEach(self.eventHandlers, function(handler, eventName) {
            $window.removeEventListener(eventName, handler);
        });

        //stop all videos and destroy them
        angular.forEach(self.videoPlayers, function(player, player_id) {
            // remove jwplayer
            if (player_id.indexOf("jw") != -1) {
                player.remove();
            } else {
                //... @TODO destroy for vimeo players
            }
        });
    };


    return Magazine;
}]);