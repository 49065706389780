angular.module('headart.magazine.directives', [])
.directive('pageLayout', ["MagazineControlService", function(MagazineControlService) {
    //creates a flickity magazine instance
    return {
        restrict: 'AE',
        replace: true,
        template: '<ng-include class="content-wrapper" src="::page.template.url"></ng-include>',
        scope: {
            page: '=pageLayout',
            magazine: '=',
        },
        link: function (scope, element, attrs) {
            scope.isPortrait = scope.magazine.isPortrait;
            scope.magControl = MagazineControlService;
        }//end of link function
    };
}])
.directive('jwplayer', ["$compile", "$window", function($compile, $window) {
    //creates a JW Player
    return {
        restrict: 'EC',
        replace: true,
        scope: {
            jwconfig: '=jwconfig',
            readyPromise: '=readyPromise'

        },
        link: function (scope, element, attrs) {
            var id = scope.jwconfig.player_id || 'jwplayer_random_' + Math.floor((Math.random() * 999999999) + 1);
            var getTemplate = function (playerId) {
                return '<div id="' + playerId + '"></div>';
            };

            element.html(getTemplate(id));
            $compile(element.contents())(scope);

            // create the player
            scope.player = jwplayer(id).setup(scope.jwconfig);
            if (scope.readyPromise) {
                // resolve the promise for anything waiting for this player to init
                scope.readyPromise.resolve(scope.player);
            }

            // disable jw's right menu click
            element[0].addEventListener('contextmenu', function(event) {
                event.stopPropagation();
                return false;
            }, true);

            scope.player.on('ready', function() {
                // cancel video playing if in a nav click zone
                if (scope.jwconfig.navClickZones>0) {
                    element[0].addEventListener('click', function(event) {
                        //check if the click was in a navigation click zone (invisible areas on left/right side of mag)
                        var zoneSize = $window.innerWidth*scope.jwconfig.navClickZones;
                        var clickPoint = event.pageX;
                        if (clickPoint <= zoneSize) {
                            event.stopPropagation();
                        } else if (clickPoint >= $window.innerWidth-zoneSize) {
                            event.stopPropagation();
                        }
                    }, true);
                }

                // indicate that flickity should disable dragging on events from the slider
                var sliderElm = element[0].querySelector('.jw-slider-time');
                sliderElm.addEventListener('mousemove', function(event) {
                    event.noFlickityDrag = true;
                }, false);
                sliderElm.addEventListener('touchmove', function(event) {
                    event.noFlickityDrag = true;
                }, false);
                sliderElm.addEventListener('pointermove', function(event) {
                    event.noFlickityDrag = true;
                }, false);
                sliderElm.addEventListener('MSPointerMove', function(event) {
                    event.noFlickityDrag = true;
                }, false);

                // disable static click events in mag on video controls
                var controlbarElm = element[0].querySelector('.jw-controlbar ');
                controlbarElm.addEventListener('mouseup', function(event) {
                    event.noStaticClick = true;
                }, true);
                controlbarElm.addEventListener('pointerup', function(event) {
                    event.noStaticClick = true;
                }, true);
                controlbarElm.addEventListener('MSPointerUp', function(event) {
                    event.noStaticClick = true;
                }, true);
                controlbarElm.addEventListener('touchend', function(event) {
                    event.noStaticClick = true;
                }, true);
            });
        }//end of link function
    };
}])
.directive('quickSwitcher', ["debounce", "$timeout", function(debounce, $timeout) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/templates/magazine/magazine.quickswitcher.html',
        scope: {
            magazine: "=",
            showSwitcher: "="
        },
        link: function (scope, element, attrs) {
            // when the quick-switcher is opened and scrolled, determin which thumbs are in view
            // and control loading of further thumbs accordingly
            var scrollContainer, scrollElement;
            scope.firstVisibleIndex = 0;
            scope.lastVisibleIndex = 0;
            scope.preloadThumbs = 5;    // controls how many images to load before/after currently displayed thumbs

            scope.closeQuickSwitcher = function() {
                scope.showSwitcher = false
            };

            var calcVisibleThumbs = debounce(function(event) {
                // get the height of scroll container and scroll position
                // get the height of one pagePair elm
                // calculate which elements are in view

                // NB: in portrait mode cover page has only one image and will cause index to be off by 1.
                // for the purposes of this it's fine
                var styles = getComputedStyle(scrollElement);
                var elmHeight = scrollElement.clientHeight + parseInt(styles.marginTop) + parseInt(styles.marginBottom);

                scope.$evalAsync(function() {
                    scope.firstVisibleIndex = Math.floor(scrollContainer.scrollTop / elmHeight);
                    scope.lastVisibleIndex = scope.firstVisibleIndex + Math.ceil(scrollContainer.clientHeight / elmHeight) - 1;

                    // add buffer before/after visible set, limited to confines of pages length
                    scope.firstVisibleIndex = Math.max(0, scope.firstVisibleIndex - scope.preloadThumbs);
                    scope.lastVisibleIndex = Math.min(scope.magazine.landscapePages.length-1, scope.lastVisibleIndex + scope.preloadThumbs);

                    // update the newly visible thumbnails to indicate they have been viewed and can stay in DOM
                    // optimisation: only run if both first and last haven't been loaded before
                    /* - no good for now, can result in sets between two loaded sets not being loaded
                    if (
                        !scope.magazine.landscapePages[scope.firstVisibleIndex].left.thumbsLoaded ||
                        !scope.magazine.landscapePages[scope.lastVisibleIndex].left.thumbsLoaded
                    ) {
                        for (var i = scope.firstVisibleIndex; i <= scope.lastVisibleIndex; i++) {
                            scope.magazine.landscapePages[i].left.thumbsLoaded = true;
                            scope.magazine.landscapePages[i].right.thumbsLoaded = true;
                        }
                    }
                    */
                    for (var i = scope.firstVisibleIndex; i <= scope.lastVisibleIndex; i++) {
                        scope.magazine.landscapePages[i].left.thumbsLoaded = true;
                        scope.magazine.landscapePages[i].right.thumbsLoaded = true;
                    }
                });
            }, 50);


            $timeout(function() {
                scrollContainer = document.querySelector(".switcher .thumb-wrapper");
                scrollElement = document.querySelectorAll(".switcher .page-pair")[1];

                angular.element(scrollContainer).on('scroll', calcVisibleThumbs);

                scope.$on("$destroy", function() {
                    angular.element(scrollContainer).off('scroll', calcVisibleThumbs);
                });
            });

            // calculate the thumbs to display when quickswitcher is opened
            scope.$watch('showSwitcher', function() {
                if (scope.showSwitcher) {
                    calcVisibleThumbs();

                    //wait a digest for DOM to update so we can get the offset of the required elements
                    $timeout(function() {
                        var thumbWrapper = document.querySelector('.switcher .thumb-wrapper');
                        var activeThumb = angular.element(document.querySelector('.thumb.active'));

                        // console.log('scroll switcher: ' + (activeThumb[0].offsetTop+activeThumb.parent()[0].offsetTop));
                        thumbWrapper.scrollTop = activeThumb ? (activeThumb[0].offsetTop+activeThumb.parent()[0].offsetTop) - 50 : 0;

                        // Set focus on thumb-wrapper
                        document.querySelector('.thumb-wrapper').focus();
                    });
                }
            });
        }//end of link function
    };
}])
.directive('tableOfContents', ["debounce", "$timeout", "$rootScope", function(debounce, $timeout, $rootScope) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: '/templates/magazine/magazine.table-of-contents.html',
        scope: {
            magazine: "="
        },
        link: function (scope, element, attrs) {
            // when the TOC is opened and scrolled, determin which thumbs are in view
            // and control loading of further thumbs accordingly
            var scrollContainer, scrollElement;
            scope.firstVisibleIndex = 0;
            scope.lastVisibleIndex = 0;
            scope.preloadRows = 2;    // controls how many rows to load before/after currently displayed thumbs (already does one extra regardless)
            scope.fullscreenAvailable = $rootScope.fullscreenAvailable;

            var calcVisibleThumbs = debounce(function(event) {
                // get the height & width of scroll container and scroll position
                // get the height & width of one pagePair elm
                // then calculate which elements are in view
                var styles = getComputedStyle(scrollElement);
                var elmHeight = scrollElement.clientHeight + parseInt(styles.marginTop) + parseInt(styles.marginBottom);
                var elmWidth = scrollElement.clientWidth + parseInt(styles.marginLeft) + parseInt(styles.marginRight);
                var elmsPerRow = Math.floor(scrollContainer.clientWidth / elmWidth);
                var firstVisibleRow = Math.floor(scrollContainer.scrollTop / elmHeight);
                var visibleRowCount = Math.ceil(scrollContainer.clientHeight / elmHeight);
                // debugging @TODO remove
                // console.log('width', scrollContainer.clientWidth, elmWidth, elmsPerRow);
                // console.log('height', scrollContainer.clientHeight, elmHeight, visibleRowCount, firstVisibleRow);


                scope.$evalAsync(function() {
                    scope.firstVisibleIndex = firstVisibleRow * elmsPerRow;
                    scope.lastVisibleIndex = ((firstVisibleRow+1) * elmsPerRow) + (visibleRowCount * elmsPerRow) -1;
                    // console.log('scroll', "perRow:"+elmsPerRow, scope.firstVisibleIndex, scope.lastVisibleIndex);

                    // add buffer before/after visible set, limited to confines of pages length
                    scope.firstVisibleIndex = Math.max(0, scope.firstVisibleIndex - (scope.preloadRows * elmsPerRow));
                    scope.lastVisibleIndex = Math.min(scope.magazine.landscapePages.length-1, scope.lastVisibleIndex + (scope.preloadRows * elmsPerRow));

                    // update the newly visible thumbnails to indicate they have been viewed and can stay in DOM
                    // optimisation idea: only run if both first and last haven't been loaded before
                    // or store combination (firstIdx_lastIdx) on each to determin if this set has already been loaded
                    /* - no good for now, can result in sets between two loaded sets not being loaded
                    if (
                        !scope.magazine.landscapePages[scope.firstVisibleIndex].left.thumbsLoaded ||
                        !scope.magazine.landscapePages[scope.lastVisibleIndex].left.thumbsLoaded
                    ) {
                        for (var i = scope.firstVisibleIndex; i <= scope.lastVisibleIndex; i++) {
                        scope.magazine.landscapePages[i].left.thumbsLoaded = true;
                        scope.magazine.landscapePages[i].right.thumbsLoaded = true;
                        }
                    }
                    */

                    for (var i = scope.firstVisibleIndex; i <= scope.lastVisibleIndex; i++) {
                        scope.magazine.landscapePages[i].left.thumbsLoaded = true;
                        scope.magazine.landscapePages[i].right.thumbsLoaded = true;
                    }

                    //optimisation idea: create a mutable array of functions to do the above.
                    // when each is called, it removes itself from the array so the array shortens and eventually doesn't get called anymore
                    // ...
                });
            }, 50);

            $timeout(function() {
                scrollContainer = document.querySelector(".magazine-table-contents .thumb-container");
                scrollElement = document.querySelector(".magazine-table-contents .thumb-wrapper");

                angular.element(scrollContainer).on('scroll', calcVisibleThumbs);

                scope.$on("$destroy", function() {
                    angular.element(scrollContainer).off('scroll', calcVisibleThumbs);
                });
            });

            // calculate the thumbs to display when quickswitcher is opened
            scope.$watch('magazine.display.showContents', function() {
                if (scope.magazine.display.showContents) {
                    calcVisibleThumbs();

                    //wait a digest for DOM to update so we can get the offset of the required elements
                    // $timeout(function() {
                    //     var thumbWrapper = document.querySelector('.magazine-table-contents .thumb-container');
                    //     var activeThumb = angular.element(document.querySelector('.thumb-wrapper.active'));

                    //     console.log('scroll TOC: ' + (activeThumb[0].offsetTop+activeThumb.parent()[0].offsetTop));
                    //     thumbWrapper.scrollTop = activeThumb ? (activeThumb[0].offsetTop+activeThumb.parent()[0].offsetTop) - 50 : 0;

                    //     // Set focus on thumb-container
                    //     document.querySelector('.thumb-container').focus();
                    // });
                }
            });
        }//end of link function
    };
}]);