'use strict';

// Start tracking orientation change.
var OCE = gajus.orientationchangeend({
    noChangeCountToEnd: 100,    //default 100
    noEndTimeout: 1000,         //default 1000
    debug: true                 //default false
});


//main app
var app = angular.module('headart.magazine', [
    'headart',

    'angularScreenfull',

    'headart.image.services',
    'headart.magazine.services',
    'headart.magazine.filters',
    'headart.magazine.directives',
    'headart.magazine.controllers',

    'headart.dev'   //For Development
]);
app.value('api_url', '/api/v1/');
app.value('public_url', '/');

app.config(["$compileProvider", function($compileProvider) {
    // disable saving binding information in DOM elm for debugging
    // should decrease load time significantly
    $compileProvider.debugInfoEnabled(false);
}]);
/*---State Definitions---*/
app.config(["$stateProvider", "$locationProvider", "$urlRouterProvider", "$logProvider", "$urlMatcherFactoryProvider", function($stateProvider, $locationProvider, $urlRouterProvider, $logProvider, $urlMatcherFactoryProvider) {
    $locationProvider.html5Mode(true);

    $stateProvider
        //---Magazines
        .state('app.magazine', {
            abstract: true,
            url: "/magazine",
            data: {
                access: "public"
            }
        })
        .state('app.magazine.list', {
            url: "/all?preview",
            views: {
                "mainView@": {
                    templateUrl: "/templates/magazine/magazine.list.html",
                    controller: 'MagazineListCtrl'
                }
            }
        })
        .state('app.magazine.view', {
            url: "/{slug}",
            data: {
                trailingSlash: true     //use this to rewrite url and add a trailing slash on entering state
            },
            views: {
                "mainView@": {
                    templateUrl: "/templates/magazine/magazine.view.html",
                    controller: 'MagazineViewCtrl'
                }
            }
        })
        .state('app.magazine.view.page', {
            url: "/{page}?preview",
            /*
            params: {
                page: {
                    value: null,
                    squash: true
                }
            },
            */
            views: {
                "magPage": {
                    template: "<div></div>",
                    controller: 'MagazinePageCtrl'
                }
            }
        })


        //----development, for testing---
        .state('app.dev', {
            url: "/dev",
            views: {
                "mainView@": {
                    templateUrl: "/templates/dev/dev-template.html",
                    controller: 'Prototype1Ctrl'
                }
            },
            data: {
                access: "private"
            }
        })
        .state('app.dev2', {
            url: "/dev2",
            views: {
                "mainView@": {
                    templateUrl: "/templates/dev/dev-text-template.html",
                    controller: 'Dev2Ctrl'
                }
            },
            data: {
                access: "private"
            }
        }
    );


     //-----error pages-----
    $stateProvider
        .state('error', {
            abstract: true,
            url: "",
            data: {
                access: "public"
            }
        })
        //unknown error
        .state('error.unknown', {
                views: {
                "mainView@": { template: "<h1 style='text-align: center; margin-top: 5rem'>Ooops!<br><small>Something went wrong</small></h1>", controller: 'ErrorUnknownCtrl'},
            }
        })
        //404 - Not Found
        .state('error.404', {
            url: "*path",   //use catch all to work like the $urlRouterProvider.otherwise() function without forcing a url change
                views: {
                "mainView@": { templateUrl: "/templates/errors/404.html", controller: 'Error404Ctrl' },
            }
        })
        //403 - Access Denied
        .state('error.403', {
         // url: "/access-denied",
                views: {
                "mainView@": { templateUrl: "/templates/errors/403.html", controller: 'Error403Ctrl'},
            }
        })
        //401 - Unauthorised
        .state('error.401', {
         // url: "/unauthorised",
                views: {
                "mainView@": { templateUrl: "/templates/errors/401.html", controller: 'Error401Ctrl'},
            }
        }
    );

}]);


app.run(["$rootScope", "$state", "$log", "$window", "debounce", function($rootScope, $state, $log, $window, debounce) {
    /*
    if (typeof $window.orientation == "undefined") {
        $rootScope.isPortrait = ($window.innerHeight > $window.innerWidth);
    } else {
        $rootScope.isPortrait = ($window.orientation == 0 || $window.orientation == 180);
    }
    */
    // use innerHeight/innerWidth as a sanity check...samsung tablets do things differently from everyone else with orientations
    $rootScope.isPortrait = ($window.innerHeight > $window.innerWidth);

    $rootScope.screenRatio = parseFloat(($window.innerWidth/$window.innerHeight).toFixed(2));
    if ($rootScope.isPortrait) {
        $rootScope.pageRatio = parseFloat(($window.innerHeight/$window.innerWidth).toFixed(2));
    } else {
        $rootScope.pageRatio = $rootScope.screenRatio;
    }
    // enable overCroppedMode if images will be cropped past a 16:9 - 16:10 aspect ratio
    if ($rootScope.pageRatio < 1.60 || $rootScope.pageRatio > 1.78) {
        $rootScope.overCroppedMode = true;
    } else {
        $rootScope.overCroppedMode = false;
    }
    //$rootScope.appVersion   = CONFIG.VERSION;


    //detect if this is an apple device (use to enable/disable apple specific fixes)
    if (platform.os.family == 'iOS') {
        $rootScope.isIOS = true;
    } else {
        $rootScope.isIOS = false;
    }

    //detect if this is a mobile device @TODO refine this with more checks with platform info
    if (Modernizr.touchevents && (platform.os.family == 'iOS' || $window.screen.width < 1920)) {
        //our indicators are touch events + being in the iOS family or being around the tablet size
        $rootScope.isMobile = true;
    } else {
        $rootScope.isMobile = false;
    }

    // Detect if fullscreen API is available using Modernizr
    if (Modernizr.fullscreen && (platform.os.family != 'iOS' || $window.screen.width < 670)) {
        $rootScope.fullscreenAvailable = true;
    } else {
        $rootScope.fullscreenAvailable = false;
    }

    /**
     * check the orientation of the device (or if desktop window is landscape/portrait....mainly for local testing)
     * uses debounce to limit times the function is called
     */
    $rootScope.checkOrientation = debounce(function() {
        /*
        if (typeof $window.orientation == "undefined") {
            $rootScope.isPortrait = ($window.innerHeight > $window.innerWidth);
        } else {
            $rootScope.isPortrait = ($window.orientation == 0 || $window.orientation == 180);
        }
        */
        // use innerHeight/innerWidth as a sanity check...samsung tablets do things differently from everyone else with orientations
        $rootScope.isPortrait = ($window.innerHeight > $window.innerWidth);

        console.log('isPortrait checked:' + $rootScope.isPortrait);

        // detect screen ratio and page ratio (the aspect ratio for a single page)
        $rootScope.screenRatio = parseFloat(($window.innerWidth/$window.innerHeight).toFixed(2));
        if ($rootScope.isPortrait) {
            $rootScope.pageRatio = parseFloat(($window.innerHeight/$window.innerWidth).toFixed(2));
        } else {
            $rootScope.pageRatio = $rootScope.screenRatio;
        }
        // enable overCroppedMode if images will be cropped past a 16:9 - 16:10 aspect ratio
        if ($rootScope.pageRatio < 1.60 || $rootScope.pageRatio > 1.78) {
            $rootScope.overCroppedMode = true;
        } else {
            $rootScope.overCroppedMode = false;
        }
        console.log($rootScope.screenRatio, $rootScope.pageRatio, "overcropped: " + $rootScope.overCroppedMode);
    }, 50);

    /**
     * Orientation change events
     */
    var setupOrientationListeners = function() {
        var isOrientationChanging;
        // Media matcher is the first to pick up the orientation change.
        $window.matchMedia('(orientation: portrait)').addListener(function (m) {
            console.log('orientation: first detection...');
            isOrientationChanging = true;

            $rootScope.$evalAsync(function() {
                $rootScope.$broadcast('orientationchangestart', $window.orientation);
            });
        });

        // bind to the "orientationchangeend" event (only triggers on mobile devices it seems)
        OCE.on('orientationchangeend', function (event) {
            // The orientation has changed.
            console.log("orientation change end", event);
            isOrientationChanging = false;

            //update orientation and emit event
            $rootScope.$evalAsync(function() {
                $rootScope.checkOrientation();
                $rootScope.$broadcast('orientationchangeend', $window.orientation);
            });
        });

        // bind to the "orientationchange" event (only available on mobile devices it seems)
        // NB: triggers after window.orientation changes but before screen is updated.
        /*
        $window.addEventListener("orientationchange", function() {
            console.log("orientation change start" )
            $rootScope.$evalAsync(function() {
                $rootScope.isPortrait = ($window.orientation == 0 || $window.orientation == 180);
            });
        }, false);
        */

        //broadcast event on orientation change (NB: not all devices have this event including desktop, using 'resize' is also required)
        $window.addEventListener("resize", function() {
            $rootScope.$evalAsync(function() {
                $rootScope.checkOrientation();
            });
        }, false);

    }; // end setupOrientationListeners()

    setupOrientationListeners();
}]);


//-----------------fixing vertical scroll issues------------------
// @TODO need to allow dragging and scrolling at same time, if dragging was first


/**
 * Patch Flickity's drag function to modify sensitivity of drag and lock it when scrolling text
 *
 */
var scrollThreshold = 3;       //how many pixels more you must move in y axes vs x axis to be considered scrolling text
Flickity.prototype.hasDragStarted = function( moveVector ) {
    // if y axis scrolled x pixels more than the x axis, disable drag to allow user to scroll text
    if (Math.abs(moveVector.y) - Math.abs(moveVector.x) > scrollThreshold) {
        // console.log('text scrolling');
        return false;
    }

    // start dragging after pointer has moved x pixels in either direction (3 default)
    return !this.isTouchScrolling && Math.abs( moveVector.x ) > this.options.dragThreshold;
};



// /**
//  * below function causes the "flick" to happen when a drag end doesn't result in a resting point close enough to a cell.
//  * we can modify this to implement cell grouping as well as add distance and velocity thresholds for the flick functionality
//  *
//  * flickDistanceThreshold  By adding a threshold to the distance, the user will have to flick a certain distance
//  * flickDeltaThreshold     By adding a threshold to the delta (velocity) the user will have to flick a certain speed
//  * flickDeltaOverride      Flicking hard enough overrides the distane threshold
//  */
// Flickity.prototype.dragEndBoostSelect = function() {
//   // do not boost if no previousDragX or dragMoveTime
//   if ( this.previousDragX === undefined || !this.dragMoveTime ||
//     // or if drag was held for 100 ms
//     new Date() - this.dragMoveTime > 100 ) {
//     return 0;
//   }

//   var distance = this.getCellDistance( -this.dragX, this.selectedIndex );
//   var delta = this.previousDragX - this.dragX;
//   console.info("Boost", distance, delta, 'delta compute:' + this.previousDragX +" - "+ -this.dragX);

//   var direction;
//   if (distance > 0 && delta > 0) {
//     // moving towards the right, and positive velocity
//     //direction = this.options.cellGrouping;    //the proper way to do groups, but won't snap to even pages
//     direction = ((this.selectedIndex+1) % this.options.cellGrouping) + 1;
//   } else if (distance < 0 && delta < 0) {
//     // moving towards the left, and negative velocity
//     //direction = -this.options.cellGrouping;     //the proper way to do groups, but won't snap to even pages
//     direction = -((this.selectedIndex-1) % this.options.cellGrouping) - 1;
//   } else {
//     return 0;
//   }

//   // check our threshold values. Velocity and distance must be above threshold, unless velocity is past a greater threshold
//   if ( (Math.abs(distance) > this.options.flickDistanceThreshold && Math.abs(delta) > this.options.flickDeltaThreshold) || (Math.abs(delta) > this.options.flickDeltaOverride) ) {
//     // boost to next x slides in given direction
//     return direction;
//   }
//   return 0;
// };


/**
 * modify to allow events to indicate if dragging should happen
 *
 */
Flickity.prototype._dragStart = function( event, pointer ) {
    if (event.noFlickityDrag) {
        // check if the event has been modified to disallow dragging
        return false;
    }

    this.isDragging = true;
    this.dragStartPoint = Unidragger.getPointerPoint( pointer );
    // prevent clicks
    this.isPreventingClicks = true;

    this.dragStart( event, pointer );
};
