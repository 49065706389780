angular.module('headart.magazine.controllers', [])
.controller('MagazineListCtrl', ["$scope", "$stateParams", "MagazineAPI", "HAImageService", "PhantomJs", function($scope, $stateParams, MagazineAPI, HAImageService, PhantomJs) {
    $scope.magazines = MagazineAPI.list();
    $scope.preview = $stateParams.preview;

    $scope.HAImageService = new HAImageService();

    $scope.magazines.$promise.then(function(magazines) {
        $scope.HAImageService.loadImage(magazines[0].pages[0].image.url);

        PhantomJs.htmlReady();
    });
}])
.controller('MagazineViewCtrl', ["$scope", "$filter", "$state", "$rootScope", "$log", "$stateParams", "Magazine", "$timeout", "$window", "HAImageService", "MagazineControlService", "$q", "debounce", "PhantomJs", "MetaData", "$location", function($scope, $filter, $state, $rootScope, $log, $stateParams, Magazine, $timeout, $window, HAImageService, MagazineControlService, $q, debounce, PhantomJs, MetaData, $location) {
    $scope.display = {
        pageSwitcher: false,
        shareSheet: false,
        toolbar: false,
        helpPopup: false,
        aboutPopup: false,
        contactPopup: false,
        advertisePopup: false,
        submissionsPopup: false,
        pageContent: true,
        debug: {
            orientationBorder: false
        }
    };
    $scope.results = {};        //debuggin info
    $scope.firstRun = true;
    var magOptions = {
        pagePreload: 2,
        debugMode: true,
        debugVerbosity: 3,
        autoPlayTime: 5000,
        navClickZones: $rootScope.isMobile ? 0 : 0.18    //disable click zones on mobile
    };
    $scope.imgCanvasURL = null;                         //image url(s) for current page, used to draw canvas image
    $scope.magImageService = new HAImageService();      //Head Art Image service, used to calculate brightness at certian points on image
    $scope.magazine = new Magazine($stateParams.slug, magOptions);
    if ($rootScope.isMobile) {
        $scope.magazine.mobileVideoMode = true;         //disable vimeo experimental video and other usupported features
    }

    $scope.magControl = MagazineControlService;         //a service to help control display elements of the mag

    //set the magazine onPageChange callback
    $scope.magazine.setOptions({
        onPageChange: function(pageData) {
            $log.debug('onPageChange');

            // update metadata
            if (pageData.isSpread || pageData.right == null) {
                MetaData.applyMeta(pageData.meta);
            } else {
                // add the right page as a thumbnail option
                var meta = angular.extend({}, pageData.left.meta, pageData.meta);
                meta.images = meta.images.concat(pageData.right.meta.images);
                MetaData.applyMeta(meta);
            }

            //update and check the image safeframe
            // $scope.checkPageSafeFrame();
        },  //end onPageChange
        useImageAPI: false       //toggle to load images via API or from public (first run must be API to generate images)
    });

    //set the magazine optimisations
    $scope.magazine.setOptimisations({
        delayPreloading: 1000,          //delay til after the animation, while user is reading
        delayUrlUpdate: 850,            //delay til after the animation
        delayControlColorUpdate: 850,   //delay til after the animation
        disableControlColorUpdate: false,
        videoPreload: false,            //reduce data usage and helps keep magazine more responsive by disabling video preloading
        noSpreadImages: true,           //don't load the full spread images and instead use just the left and right partials
        pageLookAhead: 10               //how many pages on either side of the current to have in the DOM (just the container element is present otherwise)
    });



    /**
     * toggle the toolbar
     * @param  {bool} force     set the state explicitly
     */
    $scope.toggleToolbar = function(force) {
        if (typeof force != "undefined") {
            $scope.display.toolbar = !!force;
        } else {
            if ($scope.display.pageSwitcher) {
                $scope.display.toolbar = false;
            } else {
                $scope.display.toolbar = !$scope.display.toolbar;
            }
        }
    };

    /**
     * toggle the pageSwitcher
     * @param  {bool} force     set the state explicitly
     */
    $scope.togglePageSwitcher = function(force) {
        if (typeof force != "undefined") {
            $scope.display.pageSwitcher = !!force;
        } else {
            $scope.display.pageSwitcher = !$scope.display.pageSwitcher;
        }
    };

    /**
     * toggle the shareSheet
     * @param  {bool} force     set the state explicitly
     */
    $scope.toggleShareSheet = function(force) {
        if (typeof force != "undefined") {
            $scope.display.shareSheet = !!force;
        } else {
            $scope.display.shareSheet = !$scope.display.shareSheet;
        }

        /* @TODO remove, obsolete
        if ($scope.display.shareSheet) {
            //calculate the UI colors once we have correct positions (wait 1 digest)
            $timeout(function() { $scope.updateControlColors(true); });
        }
        */

        //cancel autoplay
        $scope.magazine.toggleAutoplay(false);
    };

    /**
     * open a popup depending on type
     * @param  {string} type - the popup type selected
     */
    $scope.openPopup = function(type) {
        // Close any other popups currently open
        $scope.closeAllPopups();

        switch(type) {
            case 'about':
                $scope.display.aboutPopup = true;
                break;
            case 'contact':
                $scope.display.contactPopup = true;
                break;
            case 'advertise':
                $scope.display.advertisePopup = true;
                break;
            case 'submissions':
                $scope.display.submissionsPopup = true;
                break;
            case 'help':
                $scope.display.helpPopup = true;
                break;
        }
    };

    /**
     * close all popups currently open
     */
    $scope.closeAllPopups = function() {
        $scope.display.toolbar = false;
        $scope.display.pageSwitcher = false;
        $scope.display.aboutPopup = false;
        $scope.display.contactPopup = false;
        $scope.display.advertisePopup = false;
        $scope.display.submissionsPopup = false;
        $scope.display.helpPopup = false;
    };

    /**
     * toggle the text overlayed on each page
     * @param  {bool} force     set the state explicitly
     */
    $scope.togglePageContent = function(force) {
        if (typeof force != "undefined") {
            $scope.magControl.display.pageContent = !!force;
        } else {
            $scope.magControl.display.pageContent = !$scope.magControl.display.pageContent;
        }
    };

    /**
     * check safeframe for page set depending on landscape/portrait orientation
     */
    $scope.checkPageSafeFrame = debounce(function() {
        if (!$scope.magazine.isReady) {
            $log.debug('Check safeframe cancelled: magazine not ready...');
            return false;
        }
        $log.debug('Checking page safefreame');

        return false;//nocommit

        var current = $scope.magazine.currentPagePortrait;
        //process current, previous and next spread pages
        if (!$scope.magazine.isPortrait) {
            $scope.checkSafeFrame(current-2);
            $scope.checkSafeFrame(current, true);
            $scope.checkSafeFrame(current+2);
        } else {
            //process current, previous and next pages
            $scope.checkSafeFrame(current-1);
            $scope.checkSafeFrame(current, true);
            $scope.checkSafeFrame(current+1);
        }
    }, 350);

    /**
     * check a page's safe frame to see if the images are being overly cropped by background-size: cover
     * @param  {int} pageIndex      page to check
     * @param  {bool} debugDisplay  draw the safeframe overlay for debugging
     */
    $scope.checkSafeFrame = function(pageIndex, debugDisplay) {
        var winWidth = $window.innerWidth;
        var winHeight = $window.innerHeight;
        if (!$scope.magazine.pages[pageIndex]) {
            //page doesn't exist
            return false;
        }
        var page = $scope.magazine.pages[pageIndex];
        var nextPage = null;

        var safeW = null;
        var safeH = null;
        var imageW = null;
        var imageH = null;
        if ($scope.magazine.isPortrait) {
            //portrait
            safeW = page.image.meta.portrait_safeframe_x;
            safeH = page.image.meta.portrait_safeframe_y;
            imageW = page.image.width;
            imageH = page.image.height;
        } else {
            //landscape
            if (page.spread_image) {
                //get settigns from spread image
                imageW = page.spread_image.width;
                imageH = page.spread_image.height;
            } else {
                //get combined width across left and right portrait images
                nextPage = $scope.magazine.pages[pageIndex+1];
                imageW = page.image.width + nextPage.image.width;
                imageH = Math.max(page.image.height, nextPage.image.height);
            }
            safeW = page.meta.landscape_safeframe_x;
            safeH = page.meta.landscape_safeframe_y;
        }

        var screenAspectRatio = winWidth / winHeight;
        var imageAspectRatio = imageW / imageH;
        var safeAspectRatio = safeW / safeH;

        var screenAR = screenAspectRatio;
        var imageAR = imageAspectRatio;
        var safeAR = safeAspectRatio;
        var targetInnerAR = Math.abs((safeAspectRatio - imageAspectRatio));
        var targetOuterAR = (imageAspectRatio + targetInnerAR);
        var realAR = (safeAspectRatio - screenAspectRatio);

        $scope.results = {
            screenAR: screenAR.toFixed(4),
            imageAR: imageAR.toFixed(4),
            safeAR: safeAR.toFixed(4),
            targetXAR: targetInnerAR.toFixed(4),
            targetYAR: targetOuterAR.toFixed(4),
            realAR: realAR.toFixed(4),
            xCheck: (realAR <= targetInnerAR),
            yCheck: (screenAR <= targetOuterAR),
        };

        if ($scope.results.xCheck && $scope.results.yCheck) {
            // $scope.insideSafeFrame = true;
        } else {
            // $scope.insideSafeFrame = false;
        }

        //console.log(pageIndex, screenAspectRatio, imageAspectRatio, safeAspectRatio, safeW, safeH);
        //$log.debug(page.image.meta.portrait_safeframe_x, page.image.meta.portrait_safeframe_y);
        //$log.debug($scope.results);
        //$log.debug($scope.insideSafeFrame);

        page.overCropped = !$scope.insideSafeFrame;
        if (nextPage) {
            nextPage.overCropped = !$scope.insideSafeFrame;
        }


        if (debugDisplay) {
            //draw the safeframe over the page for debugging
            // Safe-frame canvas
            var canvasW = winWidth;
            var canvasH = winHeight;

            var canvas = document.getElementById("safe-frame");
            canvas.width = canvasW;
            canvas.height = canvasH;

            var scaleWidth = canvasW / imageW;
            var scaleHeight = canvasH / imageH;

            var scaleCanvas = Math.max(scaleWidth, scaleHeight);

            var imageSafeFrameX = safeW * scaleCanvas;
            var imageSafeFrameY = safeH * scaleCanvas;

            var paddingX = (canvasW - imageSafeFrameX) / 2;
            var paddingY = (canvasH - imageSafeFrameY) / 2;

            var safeFrameCanvas = canvas.getContext("2d");

            safeFrameCanvas.beginPath();
            safeFrameCanvas.lineWidth="3";
            safeFrameCanvas.strokeStyle="green";
            if (page.overCropped) {
                safeFrameCanvas.strokeStyle="red";
            }
            safeFrameCanvas.rect(paddingX, paddingY, imageSafeFrameX,imageSafeFrameY);
            safeFrameCanvas.stroke();

            //console.log(scaleWidth, scaleHeight, scaleCanvas);
            //console.log(imageW, imageH, safeW, safeH, imageSafeFrameX, imageSafeFrameY, paddingX, paddingY, scaleCanvas);
        }
    };

    /**
     * Share the magazine via a social platform
     * @param  {string} socialPlatform      the social media platform to share through
     */
    $scope.socialShare = function(socialPlatform, title, url) {
        title = title || MetaData.meta.title || ($scope.magazine.isReady ? $scope.magazine.rawData.name : "");
        url = url || $location.absUrl();

        var hashtag = "headartbenelux";
        var emailTo = "";
        var emailSubject = title;
        var emailBody = "Have a look at this revolutionary digital hair and beauty fashion swipe magazine: "
                      + "\n" + title + " \n" + url;
        var links = {
            twitter: {
                url: "http://twitter.com/intent/tweet?hashtags=" + hashtag + "&text=" + title + "&url=" + url,
                windowSpecs: "width=500, height=300, top=120, left=500"
            },
            facebook: {
                url: "http://www.facebook.com/sharer/sharer.php?u=" + url + "&title=" + title,
                windowSpecs: "width=500, height=300, top=120, left=500"
            },
            linkedin: {
                url: "http://www.linkedin.com/shareArticle?mini=true&url=" + url + "&title=" + title + "&source=headart.com",
                windowSpecs: "width=500, height=300, top=120, left=500"
            },
            google: {
                url: "https://plus.google.com/share?url=" + url,
                windowSpecs: "width=500, height=300, top=120, left=500"
            },
            email: {
                url: "mailto:" + emailTo + '?subject=' + emailSubject + '&body=' + emailBody,
                windowSpecs: null
            }
        };

        console.log(title);

        var target = "_blank"
        if (! links[socialPlatform].windowSpecs) {
            target = "_self";
        }

        window.open(links[socialPlatform].url, target, links[socialPlatform].windowSpecs);
    };


    /**
     * Run the magazine, passing in a password if required
     * NB: this function is called in the nested child state in order to get the startPage from the state params
     * @param  {string} startPage starting page (optional)
     * @param  {string} password  password for protected magazines (optional)
     */
    $scope.runMagazine = function(startPage, password) {
        if (startPage != null) {
            $scope.magazine.setOptions({
                startPage: startPage
            });
        }

        //run magazine for first time
        var startTime = new Date();
        var checkPoint = startTime;
        $scope.magazine.run(password)
            .then(function() {
                //success
                var updateTime = (new Date() - checkPoint)/1000;
                var totalTime = (new Date() - startTime)/1000;
                $log.debug("Run: Complete" + " [" + updateTime.toFixed(2) + "s]");
                $log.debug("Run: Total Time" + " [" + totalTime.toFixed(2) + "s]");
                $log.debug($scope.magazine);

                // debugging: timing
                checkPoint = new Date();

                // update metadata
                var pageData = $scope.magazine.getCurrentPage(true);
                if (pageData.isSpread || pageData.right == null) {
                    MetaData.applyMeta(pageData.meta);
                } else {
                    // add the right page as a thumbnail option
                    var meta = angular.extend({}, pageData.meta);
                    meta.images = meta.images.concat(pageData.right.meta.images);
                    MetaData.applyMeta(meta);
                }

                // indicate to PhantomJs pre-render service the mag is ready
                PhantomJs.htmlReady();

                // Check if inside safe frame on run complete
                $scope.checkPageSafeFrame();

                //Temp canvas display of canvas image for debugging
                var imgCanvas = angular.element($scope.magazine.HAImageService.canvas)
                    .css('position', 'fixed')
                    .css('top', '0')
                    .css('opacity', '0.6')
                    .css('display', 'none')
                    .css('pointer-events', 'none');
                //imgCanvas.css('display', 'block');      //toggle to display canvas for debugging
                document.body.appendChild(imgCanvas[0]);

                var updateTime = (new Date() - checkPoint)/1000;
                $log.debug("Post Run: Total Time" + " [" + updateTime.toFixed(2) + "s]");
            },
            function(err) {
                $log.error('Run: Fail', err);
                $scope.firstRun = true;

                if (err.status && err.status == 404) {
                    //missing magazine
                } else if (err.status && err.status == 403) {
                    //protected magazine
                } else {
                    //other error...
                }

                // indicate to PhantomJs pre-render service the page has finished
                PhantomJs.htmlReady();
            },
            function(update) {
                var newTime = new Date();
                var updateTime = (newTime - checkPoint)/1000;
                checkPoint = newTime;
                $log.debug("Run: " + update + " [" + updateTime.toFixed(2) + "s]");
            })
            .catch(function (err) {
                // indicate to PhantomJs pre-render service the page has finished
                PhantomJs.htmlReady();
            })
        ;

        $scope.firstRun = false;
    };


    /*
     * magazine is now ready to be run by child scope.
     *
     * See MagazinePageCtrl below...
     *
     */

    //need to ensure the nested state below will always run, even without the page param or a trailing slash
    //...
    //@TODO would prefer to have this in the routing somewhere
    if ($state.current.name == "app.magazine.view") {
        $state.go(".page");
    }


    // Check if inside safe frame while window resizes
    angular.element($window).on('resize', function(){
        $scope.checkPageSafeFrame();

        //update controls' colors
        $scope.magazine.updateControlColors();
    });


    // on orientation change, reflow the magazine
    // @TODO maybe the magazine could watch $rootScope instead...
    /*
    $scope.$on('orientationchange', function(event, value) {
        $scope.$evalAsync(function() {
            //alert("or: "+value+" isPortrait: "+$rootScope.isPortrait);
            if ($rootScope.isPortrait) {
                document.querySelector('.magazine-container').style.border = "2px solid red";
            } else {
                document.querySelector('.magazine-container').style.border = "2px solid blue";
            }
            $scope.magazine.togglePortraitMode($rootScope.isPortrait);
        });
    });
    //*/
    $rootScope.$watch('isPortrait', function(event, value) {
        //alert("or: "+value+" isPortrait: "+$rootScope.isPortrait);
        if ($scope.display.debug.orientationBorder) {
            if ($rootScope.isPortrait) {
                document.querySelector('.magazine-container').style.border = "2px solid red";
            } else {
                document.querySelector('.magazine-container').style.border = "2px solid orange";
            }
        }
        $scope.magazine.togglePortraitMode($rootScope.isPortrait);
        $scope.magazine.updateControlColors();
    });
    // on an orientation change end event we need to get the magazine to update flickiy
    // the screen will only be the correct size then
    $scope.$on('orientationchangeend', function(event, value) {
        $scope.$evalAsync(function() {
            //alert("or: "+value+" isPortrait: "+$rootScope.isPortrait);
            if ($scope.display.debug.orientationBorder) {
                if ($rootScope.isPortrait) {
                    document.querySelector('.magazine-container').style.border = "2px solid blue";
                } else {
                    document.querySelector('.magazine-container').style.border = "2px solid green";
                }
            }
            $scope.magazine.togglePortraitMode($rootScope.isPortrait);

            // recalculate control colours
            $scope.magazine.updateControlColors();
        });
    });

    //tear down on scope destroy (unbind listeners, etc)
    $scope.$on('$destroy', function() {
        $log.debug('Tearing down...');
        angular.element($window).off('resize', $scope.checkPageSafeFrame);

        $scope.magazine.destroy();
    });






    //------------debugging----------
    $rootScope.$on("dom-ready-magazine", function(event, val) {
        console.log("event: dom-ready-magazine");
    });

    //nocommit
    //$scope.magazine.mobileVideoMode = true;

}])
.controller('MagazinePageCtrl', ["$scope", "$stateParams", "$log", "PhantomJs", "MetaData", "$location", function($scope, $stateParams, $log, PhantomJs, MetaData, $location) {

    //on a first load create the actual magazine (on the appropriate page according to state params)
    if ($scope.$parent.firstRun) {
        //run the magazine using this state's param for start page
        $scope.runMagazine($stateParams.page);
    } else {
        /*---@TODO needs more work
        // do certain actions on the current mag according to the url
        var page = $stateParams.page;
        if (isNaN(page)) {
            if (page == "cover") {
                //got to cover page
                //$scope.magazine.goToCover();
            } else if (page == "contents") {
                //display contents page
                $scope.magazine.goToContents();
            } else {
                $log.error('unknown page: ' + page);
            }
        } else {
            $scope.magazine.goToPage(page-1);
        }
        */
    }
}]);